import { FC, useState } from "react";
import { Dialog, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, useMediaQuery, useTheme } from "@mui/material";
import { ReportsModalWrapper, TitleFont } from "./ReportsModal.styled";
import User from "../../../../Models/UserModels/User";
import LandingReport from "./components/LandingReport";
import Landing from "../../../../Models/LandingModels/Landing";
import WeightNoteReport from "./components/WeightNoteReport";
import CatchReport from "./components/CatchReport";
import CatchNewsReport from "./components/CatchNewsReport";
import CatchSumReport from "./components/CatchSumReport";
import TransactionsReport from "./components/TransactionsReport";
import Gear from "../../../../Models/WeightNoteModels/Gear";
import FishingStock from "../../../../Models/CatchRegistrationModels/FishingStock";
import CatchValueReport from "./components/CatchValueReport";
import CancelIcon from '@mui/icons-material/Cancel';
import { MobilePaperComponent, PaperComponent } from "../../../Paper/CustomPaper";
import UserHarbours from "../../../../Models/UserModels/UserHarbours";

interface ReportsModalProps {
  open: boolean;
  toggleOpen: () => void;
  user: User;
  userHarbours: UserHarbours[];
  setSelectedLanding: (landing: Landing, newLandingId?: number, landings?: Landing[], newWeightNoteId?: number) => void;
  refetchLandings?: () => void;
  fishingGears: Gear[];
  fishingStock: FishingStock[];
}

interface ReportType {
  label: string;
  value: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: FC<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any;
}

/**
 * Functional component for reports.
 * @param {ReportsModalProps} props 
 * @returns {JSX} renders the report modal.
 * 
 * Responsible for rendering different types of reports.
 */

const ReportsModal: FC<ReportsModalProps> = (props: ReportsModalProps) => {
  const [reportType, setReportType] = useState<string>('landings');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event: SelectChangeEvent<string>,) => { setReportType(event.target.value); };

  const handleClose = () => {
    props.toggleOpen();
  };

  const reportTypes: ReportType[] = [
    {
      label: "Landanir", value: "landings", component: LandingReport, props: {
        user: props.user, userHarbours: props.userHarbours, setSelectedLanding: props.setSelectedLanding, refetchLandings: props.refetchLandings, toggleOpen: handleClose
      }
    },
    {
      label: "Vigtarnótur", value: "weightNotes", component: WeightNoteReport, props: {
        user: props.user, userHarbours: props.userHarbours, setSelectedLanding: props.setSelectedLanding, refetchLandings: props.refetchLandings, toggleOpen: handleClose
      }
    },
    {
      label: "Aflagrunnur", value: "catches", component: CatchReport, props:
      {
        user: props.user, userHarbours: props.userHarbours, setSelectedLanding: props.setSelectedLanding, refetchLandings: props.refetchLandings, toggleOpen: handleClose,
      }
    },
    {
      label: "Aflafréttir", value: "catchNews", component: CatchNewsReport, props: {
        user: props.user, setSelectedLanding: props.setSelectedLanding, toggleOpen: handleClose
      }
    },
    {
      label: "Hreyfingarlisti", value: "transactions", component: TransactionsReport,
      props: {
        user: props.user, toggleOpen: handleClose, fishingGears: props.fishingGears, fishingStocks: props.fishingStock
      }
    },
    {
      label: "Samtölur fisktegunda", value: "sumFish", component: CatchSumReport,
      props: { user: props.user, toggleOpen: handleClose }
    },
    {
      label: "Aflaverðmæti", value: "catchValue", component: CatchValueReport,
      props: { user: props.user, toggleOpen: handleClose }
    }
  ];

  const selectedReportType = reportTypes.find(type => type.value === reportType);

  const maxWidthMap: { [key: string]: 'sm' | 'md' | 'lg' } = {
    landings: 'md',
    catchNews: 'md',
    sumFish: 'md',
    transactions: 'lg',
  };

  return (
    <ReportsModalWrapper>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth={maxWidthMap[selectedReportType?.value || ''] || 'lg'}
      >
        <TitleFont id="draggable-dialog-title">
          Skýrslur
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>
        <FormControl>
          <InputLabel id="report-type-label">Tegund skýrslu</InputLabel>
          <Select
            labelId="report-type-label"
            id="report-type"
            value={reportType}
            label="Veldu tegund skýrslu"
            onChange={handleChange}
          >
            {reportTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedReportType && selectedReportType.component && (
          <selectedReportType.component {...selectedReportType.props} />
        )}
      </Dialog>
    </ReportsModalWrapper>
  );
};

export default ReportsModal;