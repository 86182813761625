import { FC, useEffect, useState } from "react";
import { Box, Card, CardActions, CardContent, Chip, Dialog, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import User from "../../../../Models/UserModels/User";
import { getDeviations } from "../../../../Services/LondunarkerfiAPIService";
import Deviation from "../../../../Models/DeviationModels/Deviation";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DeviationModalDetail from "./components/DeviationModalDetail/DeviationModalDetail";
import Landing from "../../../../Models/LandingModels/Landing";
import CancelIcon from '@mui/icons-material/Cancel';
import CommentIcon from '@mui/icons-material/Comment';
import BusinessIcon from '@mui/icons-material/Business';
import { MobilePaperComponent, PaperComponent } from "../../../Paper/CustomPaper";
import { logError } from "../../../../Helpers/LogError";

interface DeviationModalProps {
  open: boolean;
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: string) => void;
  user: User;
  setSelectedLanding?: (landing: Landing, newLandingId?: number, landings?: Landing[], newWeightNoteId?: number) => void;
}

/**
 * Functional component for deviation modal
 * @param {DeviationModalProps} props 
 * @returns {JSX} renders the deviation modal
 * 
 * Responsible for rendering the deviation model which contains a datagrid report showing all open deviations.
 */

const DeviationModal: FC<DeviationModalProps> = (props: DeviationModalProps) => {
  const [deviations, setDeviations] = useState<Deviation[]>([]);
  const [selectedDeviation, setSelectedDeviation] = useState<Deviation | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [deviationModalDetailOpen, setDeviationModalDetailOpen] = useState<boolean>(false);
  const ADMIN_ROLE_ID = 18;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (props.user?.role.id === ADMIN_ROLE_ID) {
      fetchData();
    }
  }, [props.user?.role.id]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const deviations = await getDeviations();
      // Sort deviations by harbourName, then by id
      const sortedDeviations = deviations?.sort((a, b) => {
        if (a?.harbour?.harbourName < b?.harbour?.harbourName) return -1;
        if (a?.harbour?.harbourName > b?.harbour?.harbourName) return 1;

        return a?.landingId - b?.landingId;
      });
      setDeviations(sortedDeviations);
      setLoading(false);
    }
    catch (error) {
      logError(error);
      setLoading(false);
    }
  };

  const handleClose = () => {
    props.toggleOpen();
  };

  const toggleDeviationModalDetail = () => {
    setDeviationModalDetailOpen(!deviationModalDetailOpen);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDataGridRowClick = (params: any) => {
    setSelectedDeviation(params.row);
    toggleDeviationModalDetail();
  };

  const handleCardClick = (deviation: Deviation) => {
    setSelectedDeviation(deviation);
    toggleDeviationModalDetail();
  }

  const columns: GridColDef[] = [
    { field: "landingId", headerName: "Löndun nr.", type: "number" },
    { field: "landingDate", headerName: "Dagsetning", type: "date", valueGetter: (params) => params.row?.landing?.landingStarts },
    { field: 'harbour', headerName: 'Höfn', valueGetter: (params) => params.row?.harbour?.harbourName },
    { field: "userComment", headerName: "Ástæða fráviks", type: "string", flex: 1 },
    { field: "hasDeviationOnWeightNote", headerName: "Á vigtarnótu", type: "boolean" },
    { field: "hasDeviationOnWeight", headerName: "Á vigtun", type: "boolean" },
    { field: "isMajorDeviation", headerName: "meiri háttar", type: "boolean" },
  ];

  const DeviationAsCards = () => {
    return (
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1em' }}>
        {deviations.map((deviation) => (
          <Card key={deviation.id} sx={{ display: 'flex', flexDirection: 'column', border: '0.1em solid #1976D2', borderRadius: '0.5em', cursor: 'pointer' }}>
            <CardActions onClick={() => handleCardClick(deviation)} sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
                <Typography sx={{ display: 'flex', flexDirection: 'row', gap: '1em', wordBreak: 'break-all' }}><BusinessIcon />{deviation.harbour.harbourName}</Typography>
                <Typography sx={{ display: 'flex', flexDirection: 'row', gap: '1em', wordBreak: 'break-all' }}><CommentIcon />{deviation.userComment}</Typography>
                <div style={{ display: 'flex', gap: '1em' }}>
                  {deviation.isMajorDeviation && (<Chip label="Meiri háttar frávik" color="error" />)}
                  {deviation.hasDeviationOnWeight && (<Chip label="Á vigtun" color="warning" />)}
                  {deviation.hasDeviationOnWeightNote && (<Chip label="Á vigtarnótu" color="warning" />)}
                </div>
              </CardContent>
            </CardActions>
          </Card>
        ))}
      </Box>
    );
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle
          id="draggable-dialog-title"
          sx={{ fontSize: '1.5em', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', gap: '0.4em', lineHeight: '1.5em', cursor: 'move' }}
        >
          Frávik
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </DialogTitle>
        {isMobile || isTablet ? DeviationAsCards() : (
          <DataGrid
            rows={deviations}
            columns={columns}
            loading={loading}
            density="compact"
            onRowClick={handleDataGridRowClick}
          />
        )}
      </Dialog>
      {selectedDeviation && (
        <DeviationModalDetail
          user={props.user}
          open={deviationModalDetailOpen}
          toggleOpen={toggleDeviationModalDetail}
          showSnackbar={props.showSnackbar}
          deviation={selectedDeviation}
          setSelectedLanding={props.setSelectedLanding}
          toggleParent={handleClose}
          refetchDeviations={fetchData}
        />
      )}
    </div>
  );
};

export default DeviationModal;