/**
 * Calculates the Levenshtein distance between two strings.
 * This distance is a measure of the number of single-character edits required
 * to change one string into the other.
 *
 * @param a - The first string.
 * @param b - The second string.
 * @returns The Levenshtein distance between the two strings.
 */
export function levenshteinDistance(a: string, b: string): number {
  // Lengths of the input strings
  const m = a.length;
  const n = b.length;

  // Create a 2D array to store distances between substrings
  const dp: number[][] = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

  // Initialize the first row and column of the array
  for (let i = 0; i <= m; i++) {
    for (let j = 0; j <= n; j++) {
      if (i === 0) {
        dp[i][j] = j; // Cost of converting empty string to b[0..j]
      } else if (j === 0) {
        dp[i][j] = i; // Cost of converting empty string to a[0..i]
      } else {
        // Choose the minimum cost among insertion, deletion, and substitution
        dp[i][j] = Math.min(
          dp[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1), // Substitution
          dp[i - 1][j] + 1, // Deletion
          dp[i][j - 1] + 1 // Insertion
        );
      }
    }
  }

  // The Levenshtein distance is the cost of converting a to b
  return dp[m][n];
}
