import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

interface SnackBarComponentProps {
  successMsg: string;
  isOpen: boolean;
  onClose: () => void;
  autoHideDurationMS: number;
  severity: AlertColor;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/** 
 * SnackBarComponent which should show success or error messages on actions throughout the application.
 * @param {SnackBarComponentProps} props
 * - takes in a success message
 * - takes in a boolean to determine if the snackbar is open
 * - takes in a function to be called when the snackbar closes
 * - takes in a number to determine how long the snackbar should be open
 * - takes in a string to determine the severity of the snackbar
 * @returns {JSX} - Responsible for returning the snackbar.
 */

const SnackBarComponent = ({ successMsg, isOpen, onClose, autoHideDurationMS, severity }: SnackBarComponentProps) => {
  return (
    <Snackbar 
      open={isOpen} 
      autoHideDuration={autoHideDurationMS} 
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Alert onClose={onClose} severity={severity}>
        {successMsg}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarComponent;
