import User from "../../../../Models/UserModels/User";
import { Avatar, Box, Button, CircularProgress, Dialog, IconButton, InputAdornment, Tab, Tabs, TextField, useMediaQuery, useTheme } from "@mui/material"; import { CardWrapper, ItemRow, TitleFont, TypographyStyled } from "./HarbourModal.styled";
import { useEffect, useState } from "react";
import { changeUserHarbour } from "../../../../Services/LondunarkerfiAPIService";
import ChangeHarbourBody from "../../../../Models/UserModels/ChangeHarbourBody";
import UserHarbours from "../../../../Models/UserModels/UserHarbours";
import SearchIcon from "@mui/icons-material/Search";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import EditIcon from '@mui/icons-material/Edit';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HomeIcon from '@mui/icons-material/Home';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from "@mui/lab";
import { MobilePaperComponent, PaperComponent } from "../../../Paper/CustomPaper";
import { logError } from "../../../../Helpers/LogError";
import { grey } from "@mui/material/colors";

interface HarbourModalProps {
  harbours: UserHarbours[];
  harboursLoading: boolean;
  user: User;
  open: boolean;
  handleClose: () => void;
  showSnackbar: (message: string, severity: string) => void;
  refetchHarbours: () => void;
  refetchLandings: () => Promise<void>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabValue: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HarbourModal(props: HarbourModalProps) {
  const [filteredHarbours, setFilteredHarbours] = useState<UserHarbours[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [homeAddress, setHomeAddress] = useState<string>("");
  const [harbourIcon, setHarbourIcon] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const selectedHarbour = props.harbours.find(harbour => harbour.id === props.user?.userPickedHarbour);
  const [fileName, setFileName] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setSearchTerm('');
    setFilteredHarbours(props.harbours);
  }, [props.harbours, props.open]);

  useEffect(() => {
    if (selectedHarbour) {
      setPhoneNumber(selectedHarbour?.phoneNumber || '');
      setHomeAddress(selectedHarbour?.homeAddress || '');
    }
  }, [selectedHarbour]);

  useEffect(() => {
    setFilteredHarbours(props.harbours);
  }, [props.harbours]);

  const handleSubmit = async (harbourId: number | undefined) => {
    try {
      setLoading(true);
      if (!harbourId) {
        setLoading(false);
        return;
      }
      const change: ChangeHarbourBody = {
        changeToHarbourID: harbourId,
      };
      await changeUserHarbour(change);
      if (props.user) {
        props.user.userPickedHarbour = harbourId;
        const harbour = props.harbours.find(harbour => harbour.id === harbourId);
        if (harbour) {
          props.user.userPickedHarbourName = harbour?.harbourName;
        }
      }
      const harbour = props.harbours.find(harbour => harbour.id === harbourId);
      await props.refetchLandings();
      setLoading(false);
      props.showSnackbar("Skipt yfir á " + harbour?.harbourName, "success");
      props.handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const cards = filteredHarbours?.filter((harbour) => harbour.id !== props.user?.userPickedHarbour)
    .sort((a, b) => a.harbourName.localeCompare(b.harbourName))
    .map((harbour) => (
      <Button
        onClick={() => handleSubmit(harbour.id)}
        key={harbour.id}
        style={{
          width: '100%',
          cursor: 'pointer',
          padding: '1em',
          color: grey[800],
          textTransform: "none",
          boxShadow: '0 0.375rem 0.75rem rgba(140,152,164,.075)',
          border: '0.0625rem solid #e7eaf3',
          borderRadius: '0.25rem',
        }}>
        <Avatar
          src={`data:image/png;base64, ${harbour.iconBase64}`}
          alt={harbour.harbourName[0]}
        />
        <TypographyStyled style={{ flex: 1, wordWrap: 'break-word' }}>{harbour.harbourName} </TypographyStyled>
      </Button>
    ));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const filter = props.harbours.filter((harbour) => harbour.harbourName?.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredHarbours(filter);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handlePhoneNumberChange = (value: string) => {
    if (!isNaN(Number(value)) || value === "") {
      setPhoneNumber(value);
    }
  };

  const handleUpdateHarbourInfo = async () => {
    try {
      setLoading(true);
      const change: ChangeHarbourBody = {
        phoneNumber: phoneNumber,
        homeAddress: homeAddress,
        iconBase64: harbourIcon?.length === 0 ? selectedHarbour?.iconBase64 : harbourIcon
      };
      await changeUserHarbour(change);
      handleClear();
      props.showSnackbar("Hafnarupplýsingar uppfærðar", "success");
      props.refetchHarbours();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
      logError(error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFileName(file.name);

      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 500;
          const MAX_HEIGHT = 500;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          const base64String = canvas.toDataURL();
          setHarbourIcon(base64String.split(',')?.[1] || '');
        };

        img.src = reader.result?.toString() || '';
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClear = () => {
    setFileName('');
    setHarbourIcon('');
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <TitleFont id="draggable-dialog-title">
        {props.user?.userPickedHarbourName}
        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.handleClose()}>
          <CancelIcon fontSize='large' />
        </IconButton>
      </TitleFont>
      <Box sx={{ width: '100%', marginTop: isMobile ? '2.5em' : 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} orientation={isMobile ? "vertical" : "horizontal"}>
            <Tab label="Skipta um höfn" icon={<SwapHorizIcon />} iconPosition="start" {...a11yProps(0)} />
            <Tab label="Uppfæra höfn" icon={<EditIcon fontSize="small" />} iconPosition="start" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel tabValue={tabValue} index={0} >
          <ItemRow>
            <TextField
              type="search"
              label="Leita..."
              value={searchTerm}
              onChange={handleChange}
              sx={{ width: '100%', margin: '1em 0' }}
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ItemRow>
          <CardWrapper>{props.harboursLoading ? <CircularProgress /> : cards}</CardWrapper>
        </CustomTabPanel>
        <CustomTabPanel tabValue={tabValue} index={1}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
            <TextField
              label="Heimilisfang"
              value={homeAddress}
              onChange={(event) => setHomeAddress(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: 50,
                },
              }}
            />

            <TextField
              label="Símanúmer"
              type="tel"
              inputMode="tel"
              value={phoneNumber}
              onChange={(event) => handlePhoneNumberChange(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneIcon />
                  </InputAdornment>
                ),
                inputProps: {
                  maxLength: 7,
                },
              }}
            />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1em', marginBottom: '1em' }}>
              <Button
                fullWidth
                variant="outlined"
                component="label"
                startIcon={<UploadFileIcon />}
              >
                Breyta um hafnarmerki
                <input
                  id="file-input"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </Button>
              {fileName && (
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1em' }}>
                  <Avatar
                    src={`data:image/png;base64, ${harbourIcon}`}
                    alt={fileName}
                    style={{
                      width: '5em',
                      height: '5em',
                      marginLeft: '1em',
                    }}
                  />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>{fileName}</p>
                    <IconButton onClick={handleClear} size="small">
                      <ClearIcon />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
            {props.user && (
              <Avatar
                src={`data:image/png;base64, ${props.harbours.find(harbour => harbour.id === props.user?.userPickedHarbour)?.iconBase64}`}
                alt={props.user?.userPickedHarbourName}
                style={{
                  position: 'absolute',
                  top: '1.5em',
                  right: '1.5em',
                  width: '5em',
                  height: '5em',
                }}
              />
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <LoadingButton
              fullWidth
              size="small"
              disabled={(harbourIcon?.length === 0) && (phoneNumber === selectedHarbour?.phoneNumber && homeAddress === selectedHarbour?.homeAddress)}
              onClick={handleUpdateHarbourInfo}
              loading={loading}
              variant="contained"
              color="primary"
              sx={{ flex: 1 }}
            >
              <span>Uppfæra</span>
            </LoadingButton>
          </div>
        </CustomTabPanel>
      </Box>
    </Dialog>
  );
}