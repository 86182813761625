import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { NewLandingModalWrapper, TitleFont, StyledTextField } from './NewLandingModal.styled';
import { Autocomplete, Box, Dialog, IconButton, Input, InputAdornment, Slider, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Ship from '../../../../Models/ShipModels/Ship';
import { getPossibleTraceIds, postLanding } from '../../../../Services/LondunarkerfiAPIService';
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/is';
import LandingType from '../../../../Models/LandingModels/LandingType';
import LandingSubmit from '../../../../Models/LandingModels/LandingSubmit';
import User from '../../../../Models/UserModels/User';
import CancelIcon from '@mui/icons-material/Cancel';
import CommentIcon from '@mui/icons-material/Comment';
import ArticleIcon from '@mui/icons-material/Article';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { LoadingButton } from '@mui/lab';
import ShipAutoComplete from '../../../../SharedComponents/AutoComplete/ShipAutoComplete';
import TraceIds from '../../../../Models/LandingModels/TraceIds';
import Landing from '../../../../Models/LandingModels/Landing';
import { MobilePaperComponent, PaperComponent } from '../../../../SharedComponents/Paper/CustomPaper';
import { logError } from '../../../../Helpers/LogError';

interface NewLandingModalProps {
  newLandingModalOpen: boolean;
  toggleNewLandingModalOpen: () => void;
  landings: Landing[];
  landingTypes: LandingType[];
  user: User;
  fetchLandings: (setSelectedId?: number) => void;
  showSnackbar: (message: string, severity: string) => void;
}

/**
 * Functional component for NewLandingModal.
 * Displays a modal for creating a new landing.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a user object.
 * - takes in a function to fetch landings.
 * @returns {JSX} - Responsible for returning the modal to create a new landing.
 */

const NewLandingModal: FC<NewLandingModalProps> = (props: NewLandingModalProps) => {

  const [ship, setShip] = useState<Ship | null>(null);
  const [landingType, setLandingType] = useState({} as LandingType | null);
  const [landingStarts, setLandingStarts] = useState<Dayjs | null>(dayjs());
  const [comment, setComment] = useState<string>('');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [possibleTraceIds, setPossibleTraceIds] = useState<TraceIds[]>([]);
  const [selectedTraceId, setSelectedTraceId] = useState<TraceIds | null>(null);
  const [traceDateRange, setTraceDateRange] = useState<number>(2);
  const [sliderChangeCommitted, setSliderChangeCommitted] = useState<boolean>(false);
  const [focusedField, setFocusedField] = useState<'none' | 'ship' | 'date' | 'type' | 'traceId' >('none');
  const blurTimerRef = useRef<NodeJS.Timeout | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const DEFAULT_LANDING_TYPE_ID = 1;
  const MAX_DATE_RANGE = 5;

  useEffect(() => {
    const setDefaultLandingType = () => {
      // Default landing type is 'Almenn'.
      const defaultLandingType = props.landingTypes.find(landingType => landingType?.id === DEFAULT_LANDING_TYPE_ID);
      setLandingType(defaultLandingType ? defaultLandingType : null);
    }
    setDefaultLandingType();
  }, [props]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (submitButtonRef.current && focusedField === 'none') {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [focusedField]);

  const handleBlur = () => {
    if (blurTimerRef.current) {
      clearTimeout(blurTimerRef.current);
    }

    blurTimerRef.current = setTimeout(() => {
      setFocusedField('none');
    }, 100);
  };

  useEffect(() => {
    setLoading(true);
    const getTraceIds = async () => {
      try {
        if (ship?.shipRegistrationNumber && landingStarts) {
          const possibleTraceIds = await getPossibleTraceIds(undefined, ship?.shipRegistrationNumber, landingStarts?.toString(), traceDateRange);
          setPossibleTraceIds(possibleTraceIds);
          if (possibleTraceIds?.length === 1) {
            setSelectedTraceId(possibleTraceIds[0]);
          }
        }
        setLoading(false);
      } catch (error) {
        logError(error);
        setLoading(false);
      }
    };

    getTraceIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingStarts, ship?.shipRegistrationNumber, sliderChangeCommitted]);

  const handleTypeChange = (selectedLandingType: LandingType | null) => {
    setLandingType(selectedLandingType);
  };

  const handleShipChange = (selectedShip: Ship | null) => {
    setShip(selectedShip);
    if (!selectedShip) {
      setPossibleTraceIds([]);
      setSelectedTraceId(null);
    }
  };

  const handleTraceChange = (selectedTraceId: TraceIds | null) => {
    setSelectedTraceId(selectedTraceId);
  };

  const handleNewLanding = () => {
    setButtonClicked(true);
    if (!ship) {
      return;
    }
    if (!landingType || landingType.id === undefined) {
      return;
    }
    if (!landingStarts || !landingStarts.isValid()) {
      setDateError('shouldDisableDate');
      return;
    }
    const newLanding: LandingSubmit = {
      harbourId: props.user?.userPickedHarbour,
      landingStarts: landingStarts?.toDate(),
      shipNumber: ship?.shipRegistrationNumber,
      landingTypeId: landingType?.id,
      userId: props.user?.id,
      traceId: selectedTraceId?.traceID,
      comment: comment
    };
    AddLanding(newLanding);
  }

  async function AddLanding(newLanding: LandingSubmit) {
    try {
      setLoading(true);
      const resp = await postLanding(newLanding);
      props.fetchLandings(resp.data);
      props.showSnackbar('Löndun skráð!', 'success');
      setShip(null);
      props.toggleNewLandingModalOpen();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp', 'error');
      }
    }
  }

  const dateErrorMsg = useMemo(() => {
    switch (dateError) {
      case 'maxDate': {
        return 'Löndun má ekki vera í framtíðinni!';
      }
      case 'minDate': {
        return 'Löndun má ekki vera eldri en 30 dagar!';
      }
      case 'invalidDate': {
        return 'Ógild dagsetning!';
      }
      case 'shouldDisableDate': {
        return 'Veldu dagsetningu!';
      }
      default: {
        return '';
      }
    }
  }, [dateError]);

  const handleInputDateRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSliderChangeCommitted(!sliderChangeCommitted);
    const newValue = Number(event.target.value);
    if (newValue > MAX_DATE_RANGE) {
      setTraceDateRange(MAX_DATE_RANGE);
    } else if (newValue < 1) {
      setTraceDateRange(1);
    } else {
      setTraceDateRange(newValue);
    }
  };

  return (
    <NewLandingModalWrapper>
      <Dialog
        open={props.newLandingModalOpen}
        onClose={!loading ? props.toggleNewLandingModalOpen : undefined}
        disableEscapeKeyDown={loading}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <TitleFont id="draggable-dialog-title">Ný löndun
          <IconButton size='large' sx={{ position: 'absolute', top: 0, right: 0 }} onClick={props.toggleNewLandingModalOpen}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>
        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
          <Autocomplete
            sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
            disablePortal
            disabled={loading}
            options={props.landingTypes}
            getOptionLabel={(option) => option.name || ""}
            value={landingType ? landingType : null}
            onChange={(e, value) => handleTypeChange(value)}
            onFocus={() => setFocusedField('type')}
            onBlur={handleBlur}
            blurOnSelect
            isOptionEqualToValue={(option, value) => option.id === value.id}
            slotProps={{
              popper: {
                disablePortal: false,
              }
            }}
            renderInput={(params) =>
              <StyledTextField
                label="Gerð löndunar"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  required: true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ArticleIcon />
                    </InputAdornment>
                  )
                }}
                required
                error={buttonClicked && (!landingType || landingType.id === undefined)}
                helperText={buttonClicked && (!landingType || landingType.id === undefined) ? 'Veldu Landanagerð' : ''}
              />}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
            <DatePicker
              disabled={loading}
              label='Löndun hefst'
              value={landingStarts}
              onChange={(newValue: Dayjs | null) => {
                setLandingStarts(dayjs(newValue));
              }
              }
              format="DD.MM.YYYY"
              onError={(newError) => setDateError(newError)}
              slotProps={{
                textField: {
                  helperText: dateErrorMsg,
                  error: dateError !== null,
                  required: true
                },
              }}
              maxDate={dayjs()}
              minDate={dayjs().subtract(30, 'days')}
            />
          </LocalizationProvider>
        </div>

        <ShipAutoComplete
          selectedShip={ship}
          onShipChange={handleShipChange}
          onFocus={() => setFocusedField('ship')}
          onBlur={handleBlur}
          autofocus={true}
          errorState={props.landings.some(landing => landing.ship?.shipRegistrationNumber === ship?.shipRegistrationNumber)}
          errorText={props.landings.some(landing => landing.ship?.shipRegistrationNumber === ship?.shipRegistrationNumber) ? 'Skipið er með aðra löndun opna!' : ''}
        />

        <TextField
          id="outlined-multiline-static"
          label="Seljandi"
          value={ship ?
            ship?.sellerName + "\n" +
            ship?.sellerSSN + "\n" +
            ship?.sellerPlace + "\n" +
            ship?.sellerAddress
            : ''
          }
          multiline
          disabled
          rows={4}
        />

        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
          <Autocomplete
            disabled={loading}
            loading={loading}
            noOptionsText="Engar niðurstöður"
            disablePortal
            sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
            options={possibleTraceIds}
            getOptionLabel={(option) => option.huntingTripInfo || ""}
            value={selectedTraceId ? selectedTraceId : null}
            onChange={(e, value) => handleTraceChange(value)}
            onFocus={() => setFocusedField('traceId')}
            onBlur={handleBlur}
            blurOnSelect
            isOptionEqualToValue={(option, value) => option.traceID === value.traceID}
            slotProps={{
              popper: {
                disablePortal: false,
              }
            }}
            renderInput={(params) =>
              <StyledTextField
                label="Rekjanleiki"
                color="primary"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ContentPasteSearchIcon />
                    </InputAdornment>
                  )
                }}
              />}
          />
          <Box sx={{ width: isMobile || isTablet ? '100%' : '50%' }}>
            <Typography id="date-range-slider" gutterBottom>
              Dagar frá löndun
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1em', width: '100%' }}>
              <DateRangeIcon />
              <Slider
                value={traceDateRange}
                marks
                onChange={(event, newValue) => setTraceDateRange(newValue as number)}
                onChangeCommitted={() => setSliderChangeCommitted(!sliderChangeCommitted)}
                disabled={loading}
                min={1}
                max={MAX_DATE_RANGE}
                aria-labelledby='date-range-slider'
                valueLabelDisplay='auto'
              />
              <Input
                value={traceDateRange}
                onChange={handleInputDateRangeChange}
                size="small"
                inputProps={{
                  step: 1,
                  min: 1,
                  max: MAX_DATE_RANGE,
                  type: 'number',
                  'aria-labelledby': 'date-range-slider',
                }}
              />
            </Box>
          </Box>
        </div>

        <TextField
          disabled={loading}
          label="Athugasemd"
          onChange={(event) => setComment(event.target.value)}
          value={comment}
          InputProps={{
            inputProps: {
              maxLength: 100
            },
            startAdornment: <InputAdornment position="start"><CommentIcon /></InputAdornment>,
          }}
        />
        <LoadingButton
          ref={submitButtonRef}
          size="small"
          onClick={handleNewLanding}
          loading={loading}
          disabled={
            loading ||
            props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID) ||
            !ship ||
            !landingType ||
            !landingStarts?.isValid() ||
            props.landings.some(landing => landing.ship?.shipRegistrationNumber === ship?.shipRegistrationNumber)
          }
          variant="contained"
        >
          <span>Búa til löndun</span>
        </LoadingButton>
      </Dialog>
    </NewLandingModalWrapper>
  );
}

export default NewLandingModal;