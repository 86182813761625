import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../../Services/AuthService';
import { HeaderWrapper, LoaderWrapper, SigninOidcWrapper, TextWrapper } from './SigninOidc.styled';
import { Box, CircularProgress, Typography } from '@mui/material';
import Header from '../Header/Header';
import { getUser } from '../../Services/LondunarkerfiAPIService';

const SigninOidc: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authService = new AuthService();
    const handleLoginChange = async () => {
      try {
        await authService.completeAuthentication();
        if (authService.isLoggedIn()) {
          try {
            // GetUser can return error if the user is not authorized.
            const userData = await getUser(false);
            if (userData && userData.isReweighingUser) {
              navigate('/reweighting');
            } else {
              navigate('/landings');
            }
          } catch (err) {
            authService.logoutSilent();
            navigate('/unauthorized');
          }
        } else {
          authService.renewToken();
        }
      } catch (err) {
        authService.logoutSilent();
        authService.login();
      }
    };
    handleLoginChange();
  }, [navigate]);


  return (
    <SigninOidcWrapper>
      <HeaderWrapper>
        <Header isAuthorized={false} />
      </HeaderWrapper>
      <LoaderWrapper>
        <TextWrapper>
          <Typography variant='h5' color={'primary'}>
            Unnið úr auðkenningu
          </Typography>
        </TextWrapper>
        <Box sx={{ display: 'flex', paddingTop: '0.5em' }}>
          <CircularProgress />
        </Box>
      </LoaderWrapper>
    </SigninOidcWrapper>
  );
}

export default SigninOidc;