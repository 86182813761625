import { FC } from 'react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import UserHarbours from '../../Models/UserModels/UserHarbours';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import User from '../../Models/UserModels/User';

interface HarbourAutoCompleteProps {
  onHarbourChange: (harbour: UserHarbours | null) => void;
  selectedHarbour: UserHarbours | undefined;
  harbours: UserHarbours[];
  sx?: React.CSSProperties;
  user?: User;
}

const HarbourAutoComplete: FC<HarbourAutoCompleteProps> = (props) => {

  const handleHarbourChange = (harbour: UserHarbours | null) => {
    props.onHarbourChange(harbour);
  };

  return (
    <Autocomplete
      disablePortal
      options={props.harbours}
      loadingText="Sæki..."
      noOptionsText={"Ekkert fannst"}
      autoSelect
      autoHighlight
      disableClearable={props.user?.role.id !== Number(process.env.REACT_APP_ROLE_ADMIN_ID)}
      value={props.selectedHarbour}
      onChange={(event, value) => handleHarbourChange(value)}
      slotProps={{
        popper: {
          disablePortal: false,
        }
      }}
      getOptionLabel={(option) => option.harbourName}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Höfn"}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                <AccountBalanceIcon />
              </InputAdornment>
            )
          }}
        />
      )}
      sx={props.sx}
    />
  );
};

export default HarbourAutoComplete;