/* eslint-disable */
import { Route, Routes } from 'react-router-dom';
import Login from '../Login/Login';
import Unauthorized from '../../Pages/Unauthorized/Unauthorized';
import SigninOidc from '../SigninOidc/SigninOidc';
import Home from '../../Pages/Home/Home';
import RouteWithAuth from './RouteWithAuth';
import ReWeighting from '../../Pages/ReWeighting/ReWeighting';
import NotFound from '../../Pages/NotFound/NotFound';

interface RouteComponentProps {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

function RouteComponent(props: RouteComponentProps) {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signin-oidc" element={<SigninOidc />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="*" element={<NotFound />} />

      <Route
        path="/landings"
        element={
          <RouteWithAuth
            reWeighting={false}
            component={Home}
            darkMode={props.darkMode}
            setDarkMode={props.setDarkMode}
          />
        }
      />
      <Route
        path="/reweighting"
        element={
          <RouteWithAuth
            reWeighting={true}
            component={ReWeighting}
            darkMode={props.darkMode}
            setDarkMode={props.setDarkMode}
          />
        }
      />
    </Routes>
  );
}

export default RouteComponent;
