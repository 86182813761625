/* eslint-disable camelcase */
import {
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client-ts";
export class AuthService {
  public manager: UserManager;
  private user: User | null = null;
  
  constructor() {
    // https://github.com/IdentityModel/oidc-client-js/wiki
    const settings: UserManagerSettings = {
      authority: process.env.REACT_APP_AUTHORITY? process.env.REACT_APP_AUTHORITY : '',
      client_id: '@fiskistofa.is/aflaskraning',
      redirect_uri: process.env.REACT_APP_REDIRECT_URI? process.env.REACT_APP_REDIRECT_URI : '',
      response_type: "code",
      loadUserInfo: false,
      automaticSilentRenew: true,
      post_logout_redirect_uri: `https://island.is/s/fiskistofa`,
      scope: 'openid profile offline_access @fiskistofa.is/aflaskraning',
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        token_endpoint: `${process.env.REACT_APP_API_BASE_URL}connect/token`,
        //token_endpoint: 'https://identity-server.staging01.devland.is/connect/token',
        authorization_endpoint: `${process.env.REACT_APP_AUTHORITY}connect/authorize`,
        userinfo_endpoint: `${process.env.REACT_APP_AUTHORITY}connect/userinfo`,
        end_session_endpoint: `${process.env.REACT_APP_AUTHORITY}connect/endsession`
      }
    };
    this.manager = new UserManager(settings);

    // checks if refreshing token resulted in a error because the refresh token has expired and logout the user.
    // tokens lifetime can be manipulated here: https://island.is/stjornbord/
    this.manager.events.addSilentRenewError(() => {
      this.user = null;
      this.manager.removeUser();
      this.logout();
    })
  }
  
  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }
  
  getUser(): Promise<User | null> {
    return this.manager.getUser();
  }
  
  getAuthorizationHeaderValue(): string {
    return `${this.user?.token_type} ${this.user?.access_token}`;
  }
  
  login(): Promise<void> {
    return this.manager.signinRedirect();
  }
  
  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then((user: User) => {
      this.user = user;
    });
  }
  
  renewToken(): Promise<User | null> {
    return this.manager.signinSilent();
  }
  
  logout(): Promise<void> {
    this.manager.signoutRedirect();
    return this.manager.removeUser();
  }

  logoutSilent(): void {
    this.manager.signoutSilent();
  }

}