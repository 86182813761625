import styled from 'styled-components';

export const CatchRegistrationDataGridWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 1em;
  overflow: auto;
`;

export const NoDataFound = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2em;
`;

export const NoDataFoundIcon = styled.div`
  opacity: 0.5;
`;