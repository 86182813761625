import styled from 'styled-components';

export const ReWeightingWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Splitter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100% - 56px - 2em);
  width: 100%;`;