import styled from 'styled-components';
import { DialogTitle } from '@mui/material';

export const CatchRegistrationFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const CatchRegistrationFormHeader = styled(DialogTitle)`
    font-weight: bold !important;
    width: '100%';
    cursor: move;
`;

export const CatchRegistrationFormBody = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: 0.8em;
`;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const ChildWrapper = styled.div`
    --bs-card-spacer-y: 1.3125rem;
    --bs-card-spacer-x: 1.3125rem;
    --bs-card-title-spacer-y: 0.25rem;
    --bs-card-border-width: 0.0625rem;
    --bs-card-border-color: rgba(231, 234, 243, 1);
    --bs-card-border-radius: 0.75rem;
    --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
    --bs-card-inner-border-radius: 0.6875rem;
    --bs-card-cap-padding-y: 1.3125rem;
    --bs-card-cap-padding-x: 1.3125rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    width: auto;
`;

export const ChildHeader = styled.div`
    text-transform: uppercase;
    color: #8c98a4;
    font-size: 1em;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
`;

export const ChildData = styled.span`
    color: #1e2022;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    display: flex;
    justify-content: space-between;
		height: 100%;
`;

export const MainRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 0.8em;

  @media only screen and (max-width: 800px) {
    flex-direction: column; // Stack child components on smaller screens
  }
`;