import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import { IconButton, Tooltip } from '@mui/material';

interface ExcelGeneratorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  disabled: boolean;
  fileName: string;
}

const ExcelGenerator: React.FC<ExcelGeneratorProps> = (props) => {
  const handleGenerateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(props.data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Create a blob from the workbook and save it
    XLSX.writeFile(workbook, props.fileName + '.xlsx');
  };

  return (
    <Tooltip title="Sækja allar raðir" arrow>
      <span>
        <IconButton size='small' color="primary" aria-label="sækja skjal" onClick={handleGenerateExcel} disabled={props.disabled} >
          <DownloadIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ExcelGenerator;
