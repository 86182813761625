import React, { useRef } from 'react';
import { Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';

export function PaperComponent(props: PaperProps) {
  const ref = useRef(null);
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={ref}
    >
      <Paper ref={ref} {...props} sx={{ width: '100%', border: '0.1em solid #1976D2', boxShadow: '0.1em', padding: '1em', display: 'flex', gap: '1em', flexDirection: 'column', borderRadius: '1em' }} />
    </Draggable>
  );
}

export function MobilePaperComponent(props: PaperProps) {
  return (
    <Paper {...props} sx={{ width: '100%', border: '0.1em solid #1976D2', boxShadow: '0.1em', padding: '1em', display: 'flex', gap: '1em', flexDirection: 'column', borderRadius: '1em' }} />
  );
}