import { useEffect, useMemo, useState } from "react";
import RouteComponent from "./SharedComponents/Routes/RouteComponent";
import * as Sentry from "@sentry/react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

function App() {
  const storedDarkMode = localStorage.getItem('darkMode');
  const [darkMode, setDarkMode] = useState(storedDarkMode !== null ? storedDarkMode === 'true' : false);

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode.toString());
  }, [darkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouteComponent darkMode={darkMode} setDarkMode={setDarkMode} />
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);