import { FC, useEffect, useState } from "react";
import PostCatchBody from "../../../../../Models/CatchRegistrationModels/PostCatchBody";
import WeightNote from "../../../../../Models/WeightNoteModels/WeightNote";
import { CatchRegistrationProductWrapper } from "./CatchRegistrationProductCalcModal.styled";
import { Dialog, IconButton, InputAdornment, TextField, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import BarChartIcon from '@mui/icons-material/BarChart';
import CancelIcon from '@mui/icons-material/Cancel';
import NumbersIcon from '@mui/icons-material/Numbers';
import CheckIcon from '@mui/icons-material/Check';
import { patchWeight } from "../../../../../Services/LondunarkerfiAPIService";
import CatchRegistration from "../../../../../Models/CatchRegistrationModels/CatchRegistration";
import { LoadingButton } from "@mui/lab";
import CatchDeduction from "../../../../../Models/CatchRegistrationModels/CatchDeduction";
import { MobilePaperComponent, PaperComponent } from "../../../../../SharedComponents/Paper/CustomPaper";
import { logError } from "../../../../../Helpers/LogError";

interface CatchRegistrationProductCalcModalProps {
  handleFormChange: (updatedForm: PostCatchBody) => void;
  disabled: boolean;
  selectedWeightNote: WeightNote;
  weightItem: CatchRegistration | undefined;
  showSnackbar: (message: string, severity: string) => void;
  deductions: CatchDeduction[];
  setWeightItem: (weightId: number) => Promise<void>;
  open: boolean;
  toggleOpen: () => void;
}

/**
 * Functional component for Product calculation.
 * Displays the product calculation section of the catch registration modal. Only if the weight note is of type product.
 * @param props 
 * - takes in a weightItem object
 * - takes in a function to be called when the form changes
 * - takes in the selectedWeightNote
 * @returns {JSX} - Responsible for returning the product calculation section of the catch registration modal.
 */

const CatchRegistrationProductCalc: FC<CatchRegistrationProductCalcModalProps> = (props) => {

  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const MAXIMUM_QUANTITY = 999999;

  useEffect(() => {
    setTotalQuantity(props.weightItem?.totalAmountSample ? props.weightItem.totalAmountSample : totalUnitsAssumed);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.weightItem]);

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseInt(event.target.value, 10);
    if (parsedValue > MAXIMUM_QUANTITY) {
      return;
    }
    if (isNaN(parsedValue)) {
      setTotalQuantity(0);
    } else {
      setTotalQuantity(parsedValue);
    }
  };

  const handleCalculate = async () => {
    try {
      setLoading(true);
      const calculateSample: PostCatchBody = {
        calculateSample: true,
        totalAmountSample: totalQuantity
      };
      if (props.weightItem) {
        await patchWeight(props.weightItem.id, calculateSample);
        await props.setWeightItem(props.weightItem.id);
        props.showSnackbar('Uppreikning lokið!', 'success');
        setLoading(false);
        props.toggleOpen();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');

      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const totalUnitsAssumed = props.deductions?.reduce((acc, deduction) => {
    return acc + deduction.unit;
  }, 0);

  const totalDeductionAssumed = props.deductions?.reduce((acc, deduction) => {
    return acc + (deduction.weight * deduction.unit);
  }, 0);

  const avarageAssumed = props.weightItem?.sampleAvgWeight ?? 0
    ? props.weightItem?.sampleAvgWeight ?? 0
    : totalUnitsAssumed !== 0
      ? (props.weightItem?.sampleWeight ?? 0 - totalDeductionAssumed) / totalUnitsAssumed
      : 0;

  const handleClose = () => {
    props.toggleOpen();
  };

  return (
    <CatchRegistrationProductWrapper>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <div id="draggable-dialog-title" style={{ fontSize: '1.5em', fontWeight: 'bold', lineHeight: '1.5em', margin: '0', cursor: 'move' }}>
          {props.selectedWeightNote.isClosed ? 'Uppreikningur' :'Uppreikna afurðarlínu'}
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </div>
        <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column': 'row', gap: '1em'}}>
          <TextField
            fullWidth
            type="number"
            disabled={true}
            id="units"
            label="Fjöldi eininga"
            variant="outlined"
            value={props.weightItem?.samplePackagingQuantity ?? totalUnitsAssumed}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <NumbersIcon color='primary' />
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            type="number"
            disabled={true}
            id="avgWeight"
            label="Meðalþyngd"
            variant="outlined"
            value={props.weightItem?.sampleAvgWeight ?? avarageAssumed}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BarChartIcon color='primary' />
                </InputAdornment>
              )
            }}
          />
          <TextField
            fullWidth
            type="number"
            disabled={props.selectedWeightNote.isClosed ? true : props.disabled ? true : false}
            id="totalQuantity"
            label="Heildarfjöldi umbúða"
            variant="outlined"
            onKeyDown={(e) => {
              if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+") {
                e.preventDefault()
              }
            }}
            value={totalQuantity === 0 ? '' : totalQuantity}
            onChange={handleQuantityChange}
            InputProps={{
              inputProps: {
                min: totalUnitsAssumed,
                max: MAXIMUM_QUANTITY
              },
              startAdornment: (
                <InputAdornment position="start">
                  <LayersIcon color='primary' />
                </InputAdornment>
              )
            }}
          />
        </div>
        <Tooltip followCursor title={totalQuantity < totalUnitsAssumed ? 'Heildarfjöldi verður að vera jafn og eða meiri en fjöldi í úrtaki' : ''}>
          <div>
            <LoadingButton
              size="small"
              fullWidth
              onClick={handleCalculate}
              loading={loading}
              disabled={
                loading ||
                  totalQuantity < totalUnitsAssumed ||
                  totalQuantity === 0 ||
                  props.selectedWeightNote.isClosed
              }
              endIcon={<CheckIcon />}
              color='success'
              variant="contained"
            >
              <span>Staðfesta</span>
            </LoadingButton>
          </div>
        </Tooltip>
      </Dialog>
    </CatchRegistrationProductWrapper>
  );
}

export default CatchRegistrationProductCalc;
