import { FC, useEffect, useMemo, useState } from "react";
import Email from "../../../../../Models/EmailModels/Email";
import EmailGroup from "../../../../../Models/EmailModels/EmailGroup";
import User from "../../../../../Models/UserModels/User";
import { Autocomplete, Box, Button, Dialog, IconButton, InputAdornment, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ButtonsDiv, EmailGroupModalWrapper, InputsDiv, TitleFont } from "./EmailGroupModal.styled";
import DescriptionIcon from '@mui/icons-material/Description';
import { LoadingButton } from "@mui/lab";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailGroupInput from "../../../../../Models/EmailModels/EmailGroupInput";
import { deleteEmailFromGroup, patchEmailGroup, postEmailGroup, postEmailToGroup } from "../../../../../Services/LondunarkerfiAPIService";
import { useConfirm } from "material-ui-confirm";
import { MobilePaperComponent, PaperComponent } from "../../../../Paper/CustomPaper";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { logError } from "../../../../../Helpers/LogError";
import Contact from "../../../../../Models/ContactModels/Contact";
import ContactAutoComplete from "../../../../AutoComplete/ContactAutoComplete";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabValue: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface EmailGroupModalProps {
  emails: Email[];
  emailGroups: EmailGroup[];
  selectedEmailGroup: EmailGroup | null;
  open: boolean;
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: string) => void;
  user: User;
  fetchEmailGroups: () => void;
  onRefetchAndSelectEmail: (emailId: number | null) => void;
  handleAddSingleEmail: () => void;
}

/**
 * Functional component for EmailGroupModal
 * @param {EmailGroupModalProps} props
 * @returns {JSX} - renders tabs containing EmailGroup information and EmailGroup emails
 * 
 * Responsible for CRUD operations on EmailGroups and adding and removing emails from EmailGroups
 */

const EmailGroupModal: FC<EmailGroupModalProps> = (props: EmailGroupModalProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [selectedEmail, setSelctedEmail] = useState<Email | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const confirm = useConfirm();

  useEffect(() => {
    if (props.selectedEmailGroup) {
      setDescription(props.selectedEmailGroup.description);
      if (props.selectedEmailGroup.contact) {
        setSelectedContact(props.selectedEmailGroup.contact);
      } else {
        setSelectedContact(null);
      }
    } else {
      setDescription("");
      setSelectedContact(null);
    }
  }, [props.selectedEmailGroup, props.open]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    setDescription("");
    setSelectedContact(null);
    setSelctedEmail(null);
    setTabValue(0);
    props.toggleOpen();
  };

  const handleContactChange = (value: Contact | null) => {
    setSelectedContact(value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (props.emailGroups.find(emailGroup => emailGroup.contact?.id === selectedContact?.id) && selectedContact !== null) {
        props.showSnackbar('Aðili hefur nú þegar hóp!', 'error');
        setLoading(false);
        return;
      }
      const body: EmailGroupInput = {
        harbourId: props.user?.userPickedHarbour ? props.user.userPickedHarbour : -1,
        description: description
      };
      if (selectedContact) {
        body.contactId = selectedContact.id;
      }
      const resp = await postEmailGroup(body);
      props.onRefetchAndSelectEmail(resp.data);
      props.fetchEmailGroups();
      props.showSnackbar('Hópur stofnaður!', 'success');
      setLoading(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');

      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      if (props.selectedEmailGroup) {
        const body: EmailGroupInput = {
          description: description,
          contactId: selectedContact?.id
        };
        await patchEmailGroup(props.selectedEmailGroup?.id, body);
        props.fetchEmailGroups();
      } else {
        return;
      }
      props.showSnackbar('Hópur uppfærður!', 'success');
      handleClose();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleDeleteEmailFromGroup = (groupId: number, emailId: number) => () => {
    const email = props.emails.find(email => email.id === emailId);
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun eyða netfanginu: ${email?.email} úr hópnum.`,
      confirmationText: 'Eyða',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => delEmailFromGroup(groupId, emailId))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  const delEmailFromGroup = async (groupId: number, emailId: number) => {
    setLoading(true);
    try {
      await deleteEmailFromGroup(groupId, emailId);
      props.onRefetchAndSelectEmail(props.selectedEmailGroup ? props.selectedEmailGroup.id : null);
      props.showSnackbar('Netfangi eytt úr hóp!', 'success');
      setLoading(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleEmailToGroupChange = (value: Email | null) => {
    setSelctedEmail(value);
  };

  const handleAddEmailToGroup = async () => {
    setLoading(true);
    try {
      if (selectedEmail?.id && props.selectedEmailGroup) {
        await postEmailToGroup(props.selectedEmailGroup?.id, selectedEmail.id);
      } else {
        props.showSnackbar('Villa kom upp!', 'error');
        setLoading(false);
        return;
      }
      props.showSnackbar('Netfang bætt við hóp!', 'success');
      setSelctedEmail(null);
      props.onRefetchAndSelectEmail(props.selectedEmailGroup?.id);
      setLoading(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');

      }
      else {
        logError(error);
        setLoading(false);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleAddEmail = () => {
    props.handleAddSingleEmail();
  };

  const emailInGroupColumns = useMemo<GridColDef<Email>[]>(() => [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'email', headerName: 'Netfang', flex: 1 },
    {
      field: 'actions',
      type: 'actions',
      width: 1,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            color="error"
            onClick={props.selectedEmailGroup ? handleDeleteEmailFromGroup(props.selectedEmailGroup?.id, params.id as number) : undefined}
          />
        ];
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [props.selectedEmailGroup, props.emails]);

  return (
    <EmailGroupModalWrapper>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <TitleFont id="draggable-dialog-title">
          {props.selectedEmailGroup ? 'Uppfæra hóp' : 'Bæta við hóp'}
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="group tabs">
              <Tab label="Hópur" {...a11yProps(0)} />
              {props.selectedEmailGroup && <Tab label="Netföng í hópi" {...a11yProps(1)} />}
            </Tabs>
          </Box>
        </Box>
        <CustomTabPanel tabValue={tabValue} index={0}>
          <InputsDiv>
            <TextField sx={{ width: '100%', marginBottom: '1em' }}
              disabled={loading}
              label="Lýsing"
              required
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              InputProps={{
                startAdornment: <InputAdornment position="start"><DescriptionIcon /></InputAdornment>,
              }}
            />
            <ContactAutoComplete type='Viðtakandi'onContactChange={handleContactChange} selectedContact={selectedContact} autofocus={false} />
          </InputsDiv>
          <ButtonsDiv>
            {props.selectedEmailGroup ?
              <LoadingButton
                fullWidth
                size="small"
                onClick={handleUpdate}
                loading={loading}
                disabled={
                  loading ||
                  description === '' ||
                  (props.selectedEmailGroup.description === description && props.selectedEmailGroup.contact === selectedContact) ||
                  props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)
                }
                endIcon={<SaveIcon />}
                color="primary"
                variant="contained">
                <span>Uppfæra</span>
              </LoadingButton> :
              <LoadingButton
                fullWidth
                size="small"
                onClick={handleSubmit}
                loading={loading}
                disabled={loading || description === '' || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
                endIcon={<CheckIcon />}
                color="success"
                variant="contained">
                <span>Staðfesta</span>
              </LoadingButton>
            }
          </ButtonsDiv>
        </CustomTabPanel>
        <CustomTabPanel tabValue={tabValue} index={1}>
          <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', justifyContent: 'space-between', gap: '1em', paddingBottom: '1em' }}>
            <Autocomplete
              disablePortal
              disabled={loading}
              options={props.emails.sort((a, b) => a.email.localeCompare(b.email))}
              value={selectedEmail}
              onChange={(event, value) => handleEmailToGroupChange(value)}
              getOptionLabel={(option) => option.email || ""}
              noOptionsText={
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography>Ekkert netfang fannst, villtu bæta því við?</Typography>
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    size="small"
                    onClick={handleAddEmail}
                  >
                    Bæta við netfangi
                  </Button>
                </Box>
              }
              slotProps={{
                popper: {
                  disablePortal: false,
                }
              }}
              sx={{ width: '100%' }}
              renderInput={(params) =>
                <TextField
                  label="Netfang"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    required: true,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <EmailIcon />
                      </InputAdornment>
                    )
                  }}
                  required
                  error={props.selectedEmailGroup && props.selectedEmailGroup.emails && selectedEmail && props.selectedEmailGroup.emails.find(email => email.id === selectedEmail.id) ? true : false}
                  helperText={props.selectedEmailGroup && props.selectedEmailGroup.emails && selectedEmail && props.selectedEmailGroup.emails.find(email => email.id === selectedEmail.id) ? 'Netfang er þegar í hóp!' : ''}
                />}
            />
            <LoadingButton
              onClick={handleAddEmailToGroup}
              loading={loading}
              disabled={
                loading ||
                selectedEmail === null ||
                (props.selectedEmailGroup && props.selectedEmailGroup.emails && selectedEmail && props.selectedEmailGroup.emails.find(email => email.id === selectedEmail.id) ? true : false) ||
                props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)
              }
              endIcon={<AddIcon />}
              color="primary"
              variant="contained">
              <span>Skrá</span>
            </LoadingButton>
          </div>
          {props.selectedEmailGroup && props.selectedEmailGroup.emails && props.selectedEmailGroup.emails.length > 0 && (
            <DataGrid
              rows={props.selectedEmailGroup ? props.selectedEmailGroup.emails : []}
              columns={emailInGroupColumns}
              columnVisibilityModel={{ id: false }}
            />
          )}
        </CustomTabPanel >
      </Dialog >
    </EmailGroupModalWrapper >
  );
};

export default EmailGroupModal;