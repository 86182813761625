import { FC, useEffect, useState } from "react";
import { Avatar, Box, Dialog, DialogTitle, Divider, IconButton, Stack, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import User from "../../../../../../Models/UserModels/User";
import Deviation from "../../../../../../Models/DeviationModels/Deviation";
import { changeUserHarbour, getLandingById, getWeightNoteById, patchDeviation } from "../../../../../../Services/LondunarkerfiAPIService";
import WeightNote from "../../../../../../Models/WeightNoteModels/WeightNote";
import { GridColDef } from "@mui/x-data-grid";
import CatchRegistration from "../../../../../../Models/CatchRegistrationModels/CatchRegistration";
import { Card, CardData, CardLabel, DeviationModalDetailWrapper, StyledDataGrid } from "./DeviationModalDetail.styled";
import PhishingIcon from '@mui/icons-material/Phishing';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PercentIcon from '@mui/icons-material/Percent';
import SetMealIcon from '@mui/icons-material/SetMeal';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import TagIcon from '@mui/icons-material/Tag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { LoadingButton } from "@mui/lab";
import Landing from "../../../../../../Models/LandingModels/Landing";
import ChangeHarbourBody from "../../../../../../Models/UserModels/ChangeHarbourBody";
import DeviationEdit from "../../../../../../Models/DeviationModels/DeviationEdit";
import { useConfirm } from "material-ui-confirm";
import WeightNoteTypes from "../../../../../../Constants/WeightNoteTypes";
import { MobilePaperComponent, PaperComponent } from "../../../../../Paper/CustomPaper";
import { logError } from "../../../../../../Helpers/LogError";
import Ship from "../../../../../../Models/ShipModels/Ship";

interface DeviationModalDetailProps {
  open: boolean;
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: string) => void;
  user: User;
  deviation: Deviation;
  toggleParent: () => void;
  setSelectedLanding?: (landing: Landing, newLandingId?: number, landings?: Landing[], newWeightNoteId?: number) => void;
  refetchDeviations: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabValue: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

/**
 * Functional component for deviation detail modal
 * @param {DeviationModalDetailProps} props 
 * @returns {JSX} renders the deviation detail modal
 * 
 * Responsible for rendering the deviation detail model which contains CRUD operations for deviations.
 */

const DeviationModalDetail: FC<DeviationModalDetailProps> = (props: DeviationModalDetailProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [ship, setShip] = useState<Ship | null>(null);
  const [originalWeightNote, setOriginalWeightNote] = useState<WeightNote>();
  const [reWeightingWeightNote, setReWeightingWeightNote] = useState<WeightNote>();
  const [originalWeights, setOriginalWeights] = useState<CatchRegistration[]>();
  const [reWeightingWeights, setReWeightingWeights] = useState<CatchRegistration[]>();
  const [adminComment, setAdminComment] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const confirm = useConfirm();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (!props.deviation) {
          setLoading(false);
          return;
        }
        const originalWeightNote = await getWeightNoteById(props.deviation.fromHarbourWeightNoteId);
        const reWeightingWeightNote = await getWeightNoteById(props.deviation.fromReWeighterWeightNoteId);
        const landing = await getLandingById(props.deviation.landingId);
        setShip(landing.ship);
        setOriginalWeightNote(originalWeightNote);
        setReWeightingWeightNote(reWeightingWeightNote);
        setOriginalWeights(originalWeightNote.weights);
        setReWeightingWeights(reWeightingWeightNote.weights);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        logError(error);
      }
    };

    fetchData();
  }, [props.deviation]);

  const handleClose = () => {
    setAdminComment('');
    setShip(null);
    setOriginalWeightNote(undefined);
    setReWeightingWeightNote(undefined);
    setOriginalWeights(undefined);
    setReWeightingWeights(undefined);
    props.toggleOpen();
  };

  const columns: GridColDef[] = [
    { field: "condition", headerName: "Ástand", valueGetter: (params) => params.row?.condition?.name },
    { field: 'destiny', headerName: 'Afdrif', valueGetter: (params) => params.row?.destiny?.name },
    { field: "fishingArea", headerName: "Veiðisvæði", valueGetter: (params) => params.row?.fishingArea?.name },
    { field: "fishingStock", headerName: "Veiðistofn", valueGetter: (params) => params.row?.fishingStock?.name },
    { field: "storageMethod", headerName: "Geymsluaðferð", valueGetter: (params) => params.row?.storageMethod?.name },
    { field: "underKilogram", headerName: "Undirmál", type: "boolean" },
    { field: "quantity", headerName: "Magn" },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRowClassName = (params: any) => {
    return params.row?.hasDeviation ? 'row-with-deviation' : '';
  };

  const weightNoteCard = (weightNote: WeightNote) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
        <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }}>
          {weightNote?.weightNoteType?.id === WeightNoteTypes.FROM_REWEIGHTER ? 'Endurvigtun' : 'Hafnarvog'}
        </Typography>
        <Card style={{ backgroundColor: theme.palette.mode === 'dark' ? "#272727" : undefined }}>
          <CardLabel>
            Veiðarfæri
          </CardLabel>
          <CardData>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {weightNote?.gear?.name}
            </Typography>
            <PhishingIcon sx={{ color: theme.palette.mode === "dark" ? "white" : undefined }} />
          </CardData>
        </Card>
        <Card style={{ backgroundColor: theme.palette.mode === 'dark' ? "#272727" : undefined }}>
          <CardLabel>
            Kaupandi
          </CardLabel>
          <CardData>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {weightNote?.buyer?.name}
              <br />
              {weightNote?.buyer?.ssn}
              <br />
              {weightNote?.buyer?.homeAddress}
              <br />
              {weightNote?.buyer?.postalCodeName}
            </Typography>
            <BusinessIcon sx={{ color: theme.palette.mode === "dark" ? "white" : undefined }} />
          </CardData>
        </Card>

        <Card style={{ backgroundColor: theme.palette.mode === 'dark' ? "#272727" : undefined }}>
          <CardLabel>
            Viðtakandi
          </CardLabel>
          <CardData>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {weightNote?.receiver?.name}
              <br />
              {weightNote?.receiver?.ssn}
              <br />
              {weightNote?.receiver?.homeAddress}
              <br />
              {weightNote?.receiver?.postalCodeName}
            </Typography>
            <PersonIcon sx={{ color: theme.palette.mode === "dark" ? "white" : undefined }} />
          </CardData>
        </Card>
      </div>
    );
  };

  const handleConfirm = () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun samþykkja breytingar frá endurvigtun og loka frávikinu.`,
      confirmationText: 'Samþykkja',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'success' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => confirmAndCloseDeviation())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  const confirmAndCloseDeviation = async () => {
    try {
      setLoading(true);
      const body: DeviationEdit = {
        complete: true,
        adminComment: adminComment
      };
      const originalHarbourId = props.user?.userPickedHarbour;
      await changeUserHarbour({ changeToHarbourID: props.deviation.harbour.id });
      await patchDeviation(props.deviation.id, body);
      await changeUserHarbour({ changeToHarbourID: originalHarbourId });
      props.refetchDeviations();
      setLoading(false);
      props.showSnackbar('Frávik samþykkt', 'success');
      handleClose();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleInspection = async () => {
    try {
      setLoading(true);
      const landing = await getLandingById(props.deviation.landingId);
      const change: ChangeHarbourBody = {
        changeToHarbourID: landing.harbour.id,
      };
      await changeUserHarbour(change);
      if (props.user) {
        props.user.userPickedHarbour = landing.harbour.id;
        props.user.userPickedHarbourName = landing.harbour.harbourName;
      }
      if (props.setSelectedLanding) {
        props.setSelectedLanding(landing, landing.id, undefined, props.deviation.fromReWeighterWeightNoteId);
      }
      props.toggleParent();
      handleClose();
      setLoading(false);
    } catch (error) {
      logError(error);
      setLoading(false);
      props.showSnackbar('Ekki tókst að opna löndun', 'error');
    }
  };

  const handleConfirmDeviation = () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun loka frávikinu. Ef engar breytingar hafa verið gerðar mun upprunalega skráning á höfn gilda.`,
      confirmationText: 'Ljúka',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'success' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => confirmDeviation())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  const confirmDeviation = async () => {
    try {
      setLoading(true);
      const body: DeviationEdit = {
        close: true,
        adminComment: adminComment
      };
      await patchDeviation(props.deviation.id, body);
      props.refetchDeviations();
      setLoading(false);
      props.showSnackbar('Frávik lokað', 'success');
      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleRejection = () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun hafna frávikinu. Upprunaleg skráning á höfn gildir.`,
      confirmationText: 'Hafna',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => rejectDeviation())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  const rejectDeviation = async () => {
    try {
      setLoading(true);
      const body: DeviationEdit = {
        reject: true,
        adminComment: adminComment
      };
      await patchDeviation(props.deviation.id, body);
      props.refetchDeviations();
      setLoading(false);
      props.showSnackbar('Frávik hafnað', 'success');
      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const weightsAsCards = (weights: CatchRegistration[]) => {
    if (!weights) {
      return null;
    }
    return weights.map((weight: CatchRegistration) => (
      <Card
        key={weight.id}
        style={{ backgroundColor: weight.hasDeviation ? '#ffcccb' : 'primary' }}
      >
        <Stack direction={'row'} justifyContent="space-between" alignItems="center" width={'100%'}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap' }}>
            <Typography variant="h5">
              <Stack direction="row" alignItems="center" gap={1}>
                <SetMealIcon color="primary" />
                {weight.fishingStock?.name}
              </Stack>
            </Typography>
            <Typography variant="h5" sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <SignLanguageIcon color="primary" />
                {weight.condition?.name}
              </Stack>
            </Typography>
          </div>
          <Avatar sx={{ width: '4em', height: '4em' }}>
            {weight.quantity + " kg."}
          </Avatar>
        </Stack>

        <Divider sx={{ marginTop: '1em', marginBottom: '1em' }} />

        <Stack direction={'row'} width={'100%'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardLabel>
            <LocationOnIcon color="primary" />
            {weight.fishingArea?.name}
          </CardLabel>

          <CardLabel>
            <AcUnitIcon color="primary" />
            {weight.storageMethod?.name}
          </CardLabel>

          <CardLabel>
            <ImportExportIcon color="primary" />
            {weight.destiny?.name}
          </CardLabel>
        </Stack>

        {weight.icePercentage !== 0 && (
          <Stack direction={'row'} width={'100%'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack direction={'row'}>
              <PercentIcon color="primary" />
              {weight.icePercentage + '%'}
            </Stack>
            {weight.underKilogram && 'Undirmál'}
          </Stack>
        )}
      </Card>
    ));
  };

  return (
    <DeviationModalDetailWrapper>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
        maxWidth="lg"
      >
        <DialogTitle
          id="draggable-dialog-title"
          sx={{ fontSize: '1.5em', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', gap: '0.4em', lineHeight: '1.5em', cursor: 'move' }}
        >
          Frávik - ítarlegt
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em', justifyContent: 'space-between' }}>
          <Tooltip title='hafnar frávikinu, upprunaleg vigtun gildir.' arrow>
            <LoadingButton
              onClick={handleRejection}
              loading={loading}
              disabled={loading}
              endIcon={<ThumbDownIcon />}
              color='error'
              variant="contained"
              sx={{ flex: '1', minWidth: '10em' }}
            >
              <span>Hafna</span>
            </LoadingButton>
          </Tooltip>

          <Tooltip title='Velur frávik í kerfinu til skoðunar.' arrow>
            <LoadingButton
              onClick={handleInspection}
              loading={loading}
              disabled={loading}
              endIcon={<VisibilityIcon />}
              color='primary'
              variant="contained"
              sx={{ flex: '1', minWidth: '10em' }}
            >
              <span>Skoða nánar</span>
            </LoadingButton>
          </Tooltip>

          <Tooltip title='Notandi þarf að skrá rétta vigtun, smellir svo hér til að ljúka frávikinu.' arrow>
            <LoadingButton
              onClick={handleConfirmDeviation}
              loading={loading}
              disabled={loading}
              endIcon={<ThumbUpAltIcon />}
              color='secondary'
              variant="contained"
              sx={{ flex: '1', minWidth: '10em' }}
            >
              <span>Ljúka</span>
            </LoadingButton>
          </Tooltip>

          <Tooltip title='Samþykkir breytingar frá endurvigtun sjálfvirkt og lokar fráviki.' arrow>
            <LoadingButton
              onClick={handleConfirm}
              loading={loading}
              disabled={loading}
              endIcon={<CheckIcon />}
              color='success'
              variant="contained"
              sx={{ flex: '1', minWidth: '10em' }}
            >
              <span>Samþykkja</span>
            </LoadingButton>
          </Tooltip>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em', justifyContent: 'space-between' }}>
          <TextField
            label="Athugasemd umsjón"
            value={adminComment}
            onChange={(e) => setAdminComment(e.target.value)}
            sx={{ width: isMobile || isTablet ? '100%' : '49%' }}
            inputProps={{ maxLength: 4000 }}
          />
          <TextField
            label="Athugasemd endurvigtun"
            value={props.deviation?.userComment}
            disabled
            sx={{ width: isMobile || isTablet ? '100%' : '49%' }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '1em', justifyContent: 'space-between' }}>
          <Card style={{ backgroundColor: theme.palette.mode === 'dark' ? "#272727" : undefined, width: isMobile || isTablet ? '100%' : '49%' }}>
            <CardLabel>
              Löndun nr.
            </CardLabel>
            <CardData>
              <Typography variant="body2" color={theme.palette.text.primary}>
                {props.deviation?.landingId}
              </Typography>
              <TagIcon sx={{ color: theme.palette.mode === "dark" ? "white" : undefined }} />
            </CardData>
          </Card>

          <Card style={{ backgroundColor: theme.palette.mode === 'dark' ? "#272727" : undefined, width: isMobile || isTablet ? '100%' : '49%' }}>
            <CardLabel>
              Skip
            </CardLabel>
            <CardData>
              {ship && (
                <Typography variant="body2" color={theme.palette.text.primary}>
                  {ship.shipRegistrationNumber + ' - ' + ship.name}
                </Typography>
              )}
              <DirectionsBoatIcon sx={{ color: theme.palette.mode === "dark" ? "white" : undefined }} />
            </CardData>
          </Card>
        </div>
        {(isMobile || isTablet) && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Hafnarvog" {...a11yProps(0)} />
                <Tab label="Endurvigtun" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Box>
        )}
        {(isMobile || isTablet) ? (
          <CustomTabPanel tabValue={tabValue} index={0}>
            <div style={{ display: 'flex', flexDirection: (isMobile || isTablet) ? 'column' : 'row', gap: '1em' }}>
              {originalWeightNote && weightNoteCard(originalWeightNote)}
              {weightsAsCards(originalWeights || [])}
            </div>
          </CustomTabPanel>
        ) : (
          <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
            {originalWeightNote && weightNoteCard(originalWeightNote)}
            <StyledDataGrid
              rows={originalWeights || []}
              columns={columns}
              loading={loading}
              getRowClassName={getRowClassName}
            />
          </div>
        )}
        {(isMobile || isTablet) ? (
          <CustomTabPanel tabValue={tabValue} index={1}>
            <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
              {reWeightingWeightNote && weightNoteCard(reWeightingWeightNote)}
              {weightsAsCards(reWeightingWeights || [])}
            </div>
          </CustomTabPanel>
        ) : (
          <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
            {reWeightingWeightNote && weightNoteCard(reWeightingWeightNote)}
            <StyledDataGrid
              rows={reWeightingWeights || []}
              columns={columns}
              loading={loading}
              getRowClassName={getRowClassName}
            />
          </div>
        )}
      </Dialog>
    </DeviationModalDetailWrapper>
  );
};

export default DeviationModalDetail;