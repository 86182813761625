import axios from "axios";
import { AuthService } from "./AuthService";
import Gear from "../Models/WeightNoteModels/Gear";
import ReferenceDataApiResponse from "../Models/ReferenceDataModels/ReferenceDataApiResponse";
import FishingArea from "../Models/CatchRegistrationModels/FishingArea";
import Condition from "../Models/CatchRegistrationModels/Condition";
import FishingStock from "../Models/CatchRegistrationModels/FishingStock";
import StorageMethod from "../Models/CatchRegistrationModels/StorageMethod";
import Destiny from "../Models/CatchRegistrationModels/Destiny";
import CatchCombination from "../Models/CatchRegistrationModels/CatchCombination";
import PostCatchBody from "../Models/CatchRegistrationModels/PostCatchBody";
import ReWeightingLanding from "../Models/ReWeightingModels/ReWeightingLanding";
import ReWeightingLandingAPIResponse from "../Models/ReWeightingModels/ReWeightingLandingAPIResponse";
import ReWeightingDeviation from "../Models/ReWeightingModels/ReWeightingDeviation";
import ReWeightingWeightNoteEdit from "../Models/ReWeightingModels/ReWeightingWeightNoteEdit";

const authService = new AuthService();
const API_BASE_URL: string | undefined = process.env.REACT_APP_API_BASE_URL_REWEIGHTING;

export async function getLandingsReweighting(pageNumber: number): Promise<ReWeightingLanding[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReWeightingLandingAPIResponse>(`${API_BASE_URL}landings?pageNumber=${pageNumber}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.landings;
  } catch (error) {
    throw error;
  }
}

export async function getGears(): Promise<Gear[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}reference-data/gears`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.gears.sort((a: Gear, b: Gear) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    throw error;
  }
}

export async function getFishingAreas(): Promise<FishingArea[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}reference-data/fishing-areas`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.fishingAreas.sort((a: FishingArea, b: FishingArea) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    throw error;
  }
}

export async function getStorageMethods(): Promise<StorageMethod[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}reference-data/storage-methods`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.storageMethods.sort((a: StorageMethod, b: StorageMethod) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    throw error;
  }
}

export async function getDestinies(): Promise<Destiny[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}reference-data/destinies`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.destinies.sort((a: Destiny, b: Destiny) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    throw error;
  }
}

export async function getFishingStocks(): Promise<FishingStock[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}reference-data/fishing-stocks`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.fishingStocks.sort((a: FishingStock, b: FishingStock) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    throw error;
  }
}

export async function getConditions(): Promise<Condition[]> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}reference-data/conditions`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    const sortedResponse = response.data.conditions.sort((a: Condition, b: Condition) =>
      a.name.localeCompare(b.name));
    return sortedResponse;
  } catch (error) {
    throw error;
  }
}

export async function getCatchCombinations(): Promise<CatchCombination[] | null> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReferenceDataApiResponse>(`${API_BASE_URL}reference-data/catch-combination`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data.combinations;
  } catch (error) {
    throw error;
  }
}

export async function closeWeightNote(weightNoteId: number, body: ReWeightingWeightNoteEdit) {
  try {
    const user = await authService.getUser();
    const response = await axios.patch(`${API_BASE_URL}weight-notes/${weightNoteId}`, body, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function editWeight(weightId: number, change: PostCatchBody) {
  try {
    const user = await authService.getUser();
    const response = await axios.put(`${API_BASE_URL}weights/${weightId}`, change, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.status;
  } catch (error) {
    throw error;
  }
}

export async function AddWeight(weight: PostCatchBody, weightNoteId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.post(`${API_BASE_URL}weight-notes/${weightNoteId}/weights`, weight, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.status;
  } catch (error) {
    throw error;
  }
}

export async function DeleteWeight(weightId: number) {
  try {
    const user = await authService.getUser();
    const response = await axios.delete(`${API_BASE_URL}weights/${weightId}`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.status;
  } catch (error) {
    throw error;
  }
}

export async function CheckDeviation(weightNoteId: number): Promise<ReWeightingDeviation> {
  try {
    const user = await authService.getUser();
    const response = await axios.get<ReWeightingDeviation>(`${API_BASE_URL}weight-notes/${weightNoteId}/check-deviation`, {
      headers: {
        Authorization: user?.access_token
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}