import styled from 'styled-components';

export const NewWeightNoteModalWrapper = styled.div`
`;

export const TitleFont = styled.span`
    font-size: 1.25em;
    font-weight: bold !important;
    display: flex;
    justify-content: space-between;
    gap: 0.4em;
    line-height: 1.5em;
    cursor: move;
    padding: 0.3em !important;
`;