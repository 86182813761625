import styled from 'styled-components';

export const WeightNoteInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1em;
    gap: 1em;
    height: calc(100% - 9.7em);
`;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const Card = styled.div`
    box-shadow: 0 0.375rem 0.75rem rgba(140,152,164,.075);
    --bs-card-spacer-y: 1.3125rem;
    --bs-card-spacer-x: 1.3125rem;
    --bs-card-title-spacer-y: 0.25rem;
    --bs-card-border-width: 0.0625rem;
    --bs-card-border-color: rgba(231, 234, 243, 1);
    --bs-card-border-radius: 0.75rem;
    --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
    --bs-card-inner-border-radius: 0.6875rem;
    --bs-card-cap-padding-y: 1.3125rem;
    --bs-card-cap-padding-x: 1.3125rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    cursor: pointer;
    width: auto;
    
    @media only screen and (min-width: 0px) {
        /* For mobile: */
        min-width: 100%;
    }

    @media only screen and (min-width: 800px) {
        /* For desktop: */
        min-width: calc(48.5% - 0.25em);
    }

    @media only screen and (min-width: 1200px) {
        /* For desktop: */
        min-width: calc(20% - 0.75em);
    }
`;

export const WeightNoteTitleFont = styled.div`
    font-size: 1.1em;
    font-weight: bold;
    display: flex;
    gap: 0.25em;
    line-height: 1.5em;
`;

export const NoWeightNoteFoundWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1em;
    align-items: center;
    text-align: center;
    height: 100%;
`;