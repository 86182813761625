import { FC, useEffect, useState } from 'react';
import { Dialog, IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import User from '../../../../../Models/UserModels/User';
import { getCatchCompareReport } from '../../../../../Services/LondunarkerfiAPIService';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CatchCompare from '../../../../../Models/ReportModels/CatchCompare';
import { MobilePaperComponent, PaperComponent } from '../../../../../SharedComponents/Paper/CustomPaper';
import { logError } from '../../../../../Helpers/LogError';
import CancelIcon from '@mui/icons-material/Cancel';

interface CompareCatchModalProps {
  compareCatchModalOpen: boolean;
  toggleCompareCatchModalOpen: () => void;
  user: User;
  traceId: number;
}

/**
 * Functional component for CompareCatchModal.
 * Displays a modal which compares registered catch to catch submitted via catch book (Afladagbók).
 * @param props 
 * - takes in a user object.
 * @returns {JSX} - Responsible for returning the modal to compare catch.
 */

const CompareCatchModal: FC<CompareCatchModalProps> = (props: CompareCatchModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [traceData, setTraceData] = useState<CatchCompare[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    setLoading(true);
    const getTraceIds = async () => {
      try {
        if (props.traceId && props.compareCatchModalOpen) {
          const traceData = await getCatchCompareReport(props.traceId);
          setTraceData(traceData);
        }
        setLoading(false);
      } catch (error) {
        logError(error);
        setLoading(false);
      }
    };

    getTraceIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.compareCatchModalOpen]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRowClassName = (params: any) => {
    return params.row?.registeredCatch === 0 && params.row?.declaredCatch > 0 ? 'missing' :
      params.row?.registeredCatch > 0 && params.row?.declaredCatch === 0 ? 'not-in-declared' : '';
  };

  const columns: GridColDef[] = [
    { field: "fishingStock", headerName: "Veiðistofn", valueGetter: (params) => params.row.fishingStock.name, flex: 1 },
    { field: 'registeredCatch', headerName: 'Skráð magn' },
    { field: "declaredCatch", headerName: "Magn afladagbók", flex: 1 },
    { field: "difference", headerName: "Mismunur" },
  ];

  const StyledDataGrid = styled(DataGrid)`
    .missing {
    background-color: #e57373;
    }
    .not-in-declared {
    background-color: #ffb74d;
    }
  `;

  const handleClose = () => {
    props.toggleCompareCatchModalOpen();
    setTraceData([]);
  }

  return (
    <div>
      <Dialog
        open={props.compareCatchModalOpen}
        onClose={!loading ? handleClose : undefined}
        disableEscapeKeyDown={loading}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <div id="draggable-dialog-title"
          style={{ width: '100%' }}
        >
          <Typography style={{ fontWeight: 'bold', lineHeight: '1.5em', fontSize: '1.5em', cursor: 'move' }}>
            Samanburður á afla
          </Typography>
        </div>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <IconButton onClick={props.toggleCompareCatchModalOpen}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </div>
        <StyledDataGrid
          rows={traceData}
          columns={columns}
          loading={loading}
          getRowId={(row) => row.fishingStock.id}
          getRowClassName={getRowClassName}
          autoHeight
        />
      </Dialog>
    </div>
  );
}

export default CompareCatchModal;