import { useState, FC } from 'react';
import { Autocomplete, InputAdornment, TextField, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import { getShips } from '../../Services/LondunarkerfiAPIService';
import { debounce } from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Ship from '../../Models/ShipModels/Ship';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import { levenshteinDistance } from './Helpers/levenshtein';
import { logError } from '../../Helpers/LogError';

interface ShipAutoCompleteProps {
  onShipChange: (ship: Ship | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  selectedShip: Ship | null;
  autofocus: boolean;
  errorState?: boolean;
  errorText?: string;
  sx?: React.CSSProperties;
}

const ShipAutoComplete: FC<ShipAutoCompleteProps> = (props) => {
  const [ships, setShips] = useState<Ship[]>([]);
  const [loading, setLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const MIN_LENGTH_TO_CALL_API = 2;
  const [showInvalid, setShowInvalid] = useState(false);

  const fetchShips = debounce(async (search: string): Promise<void> => {
    if (search?.length < MIN_LENGTH_TO_CALL_API) {
      setShips([]);
      setNoDataFound(false);
      return;
    }
    setLoading(true);
    try {
      const ships = await getShips(search, showInvalid);
      // Sort ships based on similarity to search query
      const sortedShips = ships.sort((a, b) => {
        const distanceA = levenshteinDistance(a.name.toLowerCase(), search.toLowerCase());
        const distanceB = levenshteinDistance(b.name.toLowerCase(), search.toLowerCase());

        return distanceA - distanceB;
      });
      setNoDataFound(sortedShips?.length === 0);
      setShips(sortedShips);
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const handleShipChange = (ship: Ship | null) => {
    props.onShipChange(ship);
  };

  const handleShowInvalidChange = () => {
    setShowInvalid(!showInvalid);
  }

  return (
    <div>
      <Autocomplete
        disablePortal
        options={ships}
        loading={loading}
        loadingText="Sæki..."
        noOptionsText={noDataFound ? "Ekkert fannst" : "Skrifaðu til að leita"}
        autoSelect
        isOptionEqualToValue={(option, value) => option.shipRegistrationNumber === value.shipRegistrationNumber}
        autoHighlight
        value={props.selectedShip}
        onChange={(event, value) => handleShipChange(value)}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        blurOnSelect
        slotProps={{
          popper: {
            disablePortal: false,
          }
        }}
        onInputChange={(event, value) => {
          if (value && !ships.some(ship => ship.name + " (" + ship.shipRegistrationNumber + ")" === value)) {
            fetchShips(value);
          }
        }}
        getOptionLabel={(option) => option.name + " (" + option.shipRegistrationNumber + ")"}
        renderOption={(optionProps, option, { inputValue }) => {
          const { key, ...restOptionProps } = optionProps as { key: string } & React.HTMLAttributes<HTMLLIElement>;
          const nameMatches = match(option.name, inputValue, { insideWords: true });
          const nameParts = parse(option.name, nameMatches);
          const registrationNumberMatches = match(String(option.shipRegistrationNumber), inputValue, { insideWords: true });
          const registrationParts = parse(String(option.shipRegistrationNumber), registrationNumberMatches);

          return (
            <li key={key} {...restOptionProps}>
              <div>
                {nameParts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                {" ("}
                {registrationParts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                {")"}
              </div>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Skip"}
            autoFocus={props.autofocus}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <DirectionsBoatIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={!props.selectedShip?.hasFishingPermit && props.selectedShip !== null || props.errorState || props.selectedShip?.hasOverFished}
            helperText={
              (!props.selectedShip?.hasFishingPermit && props.selectedShip !== null && 'Skipið er ekki með gilt veiðileyfi!') ||
              props.errorText ||
              (props.selectedShip?.hasOverFished && 'Skip er í umframafla!')
            }
          />
        )}
        sx={props.sx}
      />
      <FormControlLabel control={<Switch size='small' sx={{ mt: 1, mb: 1, ml: 1 }} checked={showInvalid} onClick={handleShowInvalidChange} />} label="Leita eftir skipum án veiðileyfis" />
    </div>
  );
};

export default ShipAutoComplete;