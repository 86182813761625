import React, { FC, useEffect } from 'react';
import { AuthService } from '../../Services/AuthService';

const Login: FC = () => {
  useEffect(() => {
    const authService = new AuthService();
    const login = () => {
      authService.login();
    };
    login();
  }, []);

  return <div></div>;
};

export default Login;