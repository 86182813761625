import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthService } from '../../Services/AuthService';
import { getUser } from '../../Services/LondunarkerfiAPIService';
import { Box, CircularProgress } from '@mui/material';
import { HeaderWrapper, LoaderWrapper, RouteWithAuthWrapper } from './RouteWithAuth.styled';
import Header from '../Header/Header';
import User from '../../Models/UserModels/User';

interface RouteWithAuthProps {
  component: React.ComponentType<{ user: User, darkMode: boolean, setDarkMode: (darkMode: boolean) => void }>;
  reWeighting: boolean;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

export default function RouteWithAuth({ component: Component, reWeighting, darkMode, setDarkMode }: RouteWithAuthProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthorization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkAuthorization() {
    const authService = new AuthService();
    const oicdUser = await authService.getUser();

    if (!oicdUser) {
      // If the user is not logged in, redirect to login page
      navigate("/", { replace: true });
      return;
    }

    try {
      const userData = await getUser(true);
      if (!userData || (reWeighting && !userData.isReweighingUser) || (!reWeighting && userData.isReweighingUser)) {
        // Unauthorized user
        setIsLoading(false);
        setIsAuthenticated(false);
        return;
      }
      // Authorized user
      setUser(userData);
      setIsAuthenticated(true);
    } catch (err) {
      // API call failed, assume unauthorized
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <RouteWithAuthWrapper>
        <HeaderWrapper>
          <Header isAuthorized={true} />
        </HeaderWrapper>
        <LoaderWrapper>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        </LoaderWrapper>
      </RouteWithAuthWrapper>
    );
  }

  if (!isAuthenticated) {
    // If unauthorized, navigate to /unauthorized
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return (
    <RouteWithAuthWrapper>
      {user && (
        <Component user={user} darkMode={darkMode} setDarkMode={setDarkMode} />
      )}
    </RouteWithAuthWrapper>
  );
}