import styled from 'styled-components';

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const Card = styled.div`
    box-shadow: 0 0.375rem 0.75rem rgba(140,152,164,.075);
    --bs-card-spacer-y: 1.3125rem;
    --bs-card-spacer-x: 1.3125rem;
    --bs-card-title-spacer-y: 0.25rem;
    --bs-card-border-width: 0.0625rem;
    --bs-card-border-color: rgba(231, 234, 243, 1);
    --bs-card-border-radius: 0.75rem;
    --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
    --bs-card-inner-border-radius: 0.6875rem;
    --bs-card-cap-padding-y: 1.3125rem;
    --bs-card-cap-padding-x: 1.3125rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    width: 17em;
    
    @media only screen and (max-width: 1600px) {
        /* For smaller screens: */
        width: 14em;
    }

    @media only screen and (max-width: 1300px) {
        /* For smaller screens: */
        width: 12.5em;
    }
`;

export const CardLabel = styled.div`
    text-transform: uppercase;
    color: #8c98a4;
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
`;

export const CardData = styled.span`
    color: #1e2022;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    display: flex;
    justify-content: space-between;
`;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const ListItemCard = styled.div`
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    background-clip: border-box;
    cursor: pointer;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
    height: 5.5em;
`;

export const ListItemCardData = styled.span`
    color: #1e2022;
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.25;
    margin: 0;
    display: flex;
    justify-content: space-between;
    gap: 0.75em;
    padding-bottom: 0.25em;
    padding-top: 0.5em;
`;

export const WeightNoteHeader = styled.div`
    display: flex;
    justify-content: space-between;
    line-height: 1.5em;
    align-items: center;
    padding-bottom: 1em;
    `;

export const WeightNoteTitleFont = styled.div`
    font-size: 1em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 0.8em;

    @media only screen and (max-width: 1200px) {
        /* For mobile: */
        flex-wrap: wrap;
    }
`;

export const NoWeightNoteFoundWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1em;
    align-items: center;
    text-align: center;
    height: 100%;
`;