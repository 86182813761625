import { DialogTitle } from "@mui/material";
import styled from "styled-components";

export const ReportsModalWrapper = styled.div`
  height: 100%;
`;

export const TitleFont = styled(DialogTitle)`
  font-size: 1.5em;
  font-weight: bold !important;
  display: flex;
  justify-content: space-between;
  gap: 0.4em;
  line-height: 1.5em;
  cursor: move;
  padding: 0.25em !important;
`;