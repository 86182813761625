import styled from 'styled-components';

export const OrganizationNameHeader = styled.h6`
  margin-block: 0;
  justify-content: space-around;
  display: flex;
  color: white;
`;

export const OrganizationNameCard = styled.h2`
  margin-block: 0;
  justify-content: space-around;
  display: flex;
`;

export const UserNameCard = styled.h4`
  margin-block: 0;
  justify-content: space-around;
  display: flex;
`;

export const UserRole = styled.h4`
  margin-block: 0;
  justify-content: space-around;
  display: flex;
  margin-bottom: 0.5em;
`;

export const OrganizationInfoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserInfoCardContainer = styled.div`
  padding: 1em;
`;

export const UserInfoHeaderContainer = styled.div`
  padding-right: 0.5em;
`;