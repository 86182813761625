import { FC, useEffect, useState } from "react";
import Email from "../../../../../Models/EmailModels/Email";
import { Dialog, IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import CommentIcon from '@mui/icons-material/Comment';
import User from "../../../../../Models/UserModels/User";
import { patchEmail, postEmail } from "../../../../../Services/LondunarkerfiAPIService";
import SaveIcon from '@mui/icons-material/Save';
import { ButtonsDiv, EmailFormModalWrapper, TitleFont } from "./EmailFormModal.styled";
import { MobilePaperComponent, PaperComponent } from "../../../../Paper/CustomPaper";
import { logError } from "../../../../../Helpers/LogError";

interface EmailFormModalProps {
  user: User;
  emails: Email[];
  editEmail: Email | null;
  open: boolean;
  toggleOpen: () => void;
  showSnackbar: (message: string, severity: string) => void;
  reFetchEmails: () => void;
}

/** 
 * Functional component for EmailFormModal
 * @param {EmailFormModalProps} props 
 * @returns {JSX} renders the EmailFormModal component
 * 
 * Responsible for rendering the EmailFormModal component which is used to add or edit an email address.
*/

const EmailFormModal: FC<EmailFormModalProps> = (props: EmailFormModalProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [emailAddress, setEmailAdress] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (props.editEmail) {
      setEmailAdress(props.editEmail.email);
      setComment(props.editEmail.comment ? props.editEmail.comment : '');
    } else {
      setEmailAdress('');
      setComment('');
    }
  }, [props]);

  const handleClose = () => {
    setEmailAdress('');
    setComment('');
    props.toggleOpen();
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const body: Email = {
        harbourId: props.user?.userPickedHarbour ? props.user.userPickedHarbour : -1,
        email: emailAddress,
        comment: comment,
      };
      await postEmail(body);
      props.reFetchEmails();
      props.showSnackbar('Netfang skráð!', 'success');
      setLoading(false);
      handleClose();
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');

      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  };

  const handleUpdate = () => {
    if (props.editEmail?.id) {
      const body: Email = {
        email: emailAddress,
        comment: comment,
      };
      editEmailAddress(props.editEmail.id, body);
    }
  };

  async function editEmailAddress(emailID: number, editBody: Email) {
    try {
      setLoading(true);
      await patchEmail(emailID, editBody);
      props.reFetchEmails();
      props.showSnackbar('Netfang uppfært!', 'success');
      setLoading(false);
      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAdress(event.target.value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(event.target.value);
    setIsEmailValid(isValid);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  return (
    <EmailFormModalWrapper>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <TitleFont id="draggable-dialog-title">
          {props.editEmail ? 'Uppfæra netfang' : 'Bæta við netfangi'}
          <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>
        <TextField
          label="Netfang"
          disabled={loading}
          value={emailAddress}
          onChange={handleEmailChange}
          required
          variant="outlined"
          error={emailAddress !== '' && !isEmailValid}
          helperText={emailAddress !== '' && !isEmailValid ? 'Þetta netfang er ekki gilt!' : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          disabled={loading}
          label="Athugasemd"
          onChange={handleCommentChange}
          value={comment}
          InputProps={{
            inputProps: {
              maxLength: 100
            },
            startAdornment: <InputAdornment position="start"><CommentIcon /></InputAdornment>,
          }}
        />
        <ButtonsDiv>
          {props.editEmail ? (
            <LoadingButton
              fullWidth
              size="small"
              onClick={handleUpdate}
              loading={loading}
              disabled={loading || emailAddress === '' || (!props.editEmail.comment && comment === '') || (props.editEmail.comment === comment) || props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)}
              endIcon={<SaveIcon />}
              color="primary"
              variant="contained">
              <span>Uppfæra</span>
            </LoadingButton>) : (
            <LoadingButton
              fullWidth
              size="small"
              onClick={handleSubmit}
              loading={loading}
              disabled={
                loading ||
                emailAddress === '' ||
                !isEmailValid ||
                (props.emails && props.emails && emailAddress && props.emails.find(email => email.email === emailAddress) ? true : false) ||
                props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID)
              }
              endIcon={<CheckIcon />}
              color="success"
              variant="contained">
              <span>Staðfesta</span>
            </LoadingButton>)}
        </ButtonsDiv>
      </Dialog>
    </EmailFormModalWrapper>
  );
};

export default EmailFormModal;