import styled from 'styled-components';

export const RouteWithAuthWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderWrapper = styled.div`
  height: 64px;
  width: 100%;
`;

export const LoaderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
`;
