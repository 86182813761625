import styled from 'styled-components';

export const LandingInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5em;
    justify-content: space-between;
`;

export const SplitContainer = styled.div`
    display: flex;
`;

export const LandingTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    line-height: 1.5em;
    flex-direction: column;
    gap: 0.5em;
`;

export const LandingTitleFont = styled.div`
    font-size: 1.5em;
    font-weight: bold;
    display: flex;
    gap: 0.4em;
    line-height: 1.5em;
`;