import { DialogTitle } from '@mui/material';
import styled from 'styled-components';

export const VehicleFormModalWrapper = styled.div`
`;

export const TitleFont = styled(DialogTitle)`
  font-size: 1.5em;
  font-weight: bold !important;
  display: flex;
  justify-content: space-between;
  gap: 0.4em;
  line-height: 1.5em;
  cursor: move;
`;