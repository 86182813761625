import { FC, useState, useEffect, useRef } from 'react';
import { CatchRegistrationCatchWrapper } from './CatchRegistrationCatch.styled';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PostCatchBody from '../../../../../Models/CatchRegistrationModels/PostCatchBody';
import CatchRegistration from '../../../../../Models/CatchRegistrationModels/CatchRegistration';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SetMealIcon from '@mui/icons-material/SetMeal';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import CatchCombination from '../../../../../Models/CatchRegistrationModels/CatchCombination';
import FishingArea from '../../../../../Models/CatchRegistrationModels/FishingArea';
import StorageMethod from '../../../../../Models/CatchRegistrationModels/StorageMethod';
import Destiny from '../../../../../Models/CatchRegistrationModels/Destiny';
import FishingStock from '../../../../../Models/CatchRegistrationModels/FishingStock';
import Condition from '../../../../../Models/CatchRegistrationModels/Condition';
import WeightNote from '../../../../../Models/WeightNoteModels/WeightNote';
import Gear from '../../../../../Models/WeightNoteModels/Gear';
import WeightNoteTypes from '../../../../../Constants/WeightNoteTypes';
import CatchRegistrationDefaults from '../../../../../Constants/CatchRegistrationDefaults';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';

interface CatchRegistrationCatchProps {
  handleFormChange: (updatedForm: PostCatchBody) => void;
  allowedCombinations: CatchCombination[] | null;
  gears?: Gear[];
  weight: CatchRegistration | undefined;
  selectedWeightNote: WeightNote;
  fishAreas: FishingArea[];
  storageMethods: StorageMethod[];
  destinies: Destiny[];
  fishingStocks: FishingStock[];
  conditions: Condition[];
  disabled: boolean;
  containsExported: boolean;
}

/**
 * Functional component for CatchRegistrationCatch.
 * Displays the catch form for the catch registration modal.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in a function to be called when the form changes.
 * - takes in a boolean to determine if the weight note is closed.
 * - takes in a CatchRegistration object to determine if the modal is in edit state.
 * - takes in a CatchCombination array to determine which combinations are allowed (this component handles filtering of allowed catch based on changes).
 * 
 * @returns {JSX} - Responsible for returning the catch form.
 */

const CatchRegistrationCatch: FC<CatchRegistrationCatchProps> = (props) => {
  const [selectedFishingArea, setSelectedFishingArea] = useState<FishingArea | undefined>();
  const [selectedStorageMethod, setSelectedStorageMethod] = useState<StorageMethod | undefined>();
  const [selectedDestiny, setSelectedDestiny] = useState<Destiny | undefined>();
  const [selectedStock, setSelectedStock] = useState<FishingStock | undefined>();
  const [selectedCondition, setSelectedCondition] = useState<Condition | undefined>();
  const [fishingAreaInputValue, setFishingAreaInputValue] = useState<string>('');
  const [storageMethodInputValue, setStorageMethodInputValue] = useState<string>('');
  const [destinyInputValue, setDestinyInputValue] = useState<string>('');
  const [stockInputValue, setStockInputValue] = useState<string>('');
  const [conditionInputValue, setConditionInputValue] = useState<string>('');
  const [isUnderKilogram, setIsUnderKilogram] = useState(false);
  const [isUnderKilogramDisabled, setIsUnderKilogramDisabled] = useState<boolean>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (props.allowedCombinations) {
      const filteredCombinations = props.allowedCombinations.filter((combination) => {
        return (
          (!selectedFishingArea || combination.fishingAreaId === selectedFishingArea.id) &&
          (!selectedStorageMethod || combination.storageMethodId === selectedStorageMethod.id) &&
          (!selectedDestiny || combination.destinyId === selectedDestiny.id) &&
          (!selectedStock || combination.fishStockId === selectedStock.id) &&
          (!selectedCondition || combination.conditionId === selectedCondition.id)
        );
      });
      const isUnderKilogramDisabled = filteredCombinations.every((combination) => combination.isUnderKilogram === false);
      setIsUnderKilogramDisabled(isUnderKilogramDisabled);
    }
  }, [selectedStock, selectedFishingArea, selectedCondition, selectedStorageMethod, selectedDestiny, isUnderKilogram, props.allowedCombinations]);

  // Default Autocomplete values
  const defaultFishingArea = props.fishAreas.find((area) => area.id === CatchRegistrationDefaults.FISHING_AREA_ICELAND_ID);
  const defaultStorageMethod = (
    props.selectedWeightNote.weightNoteType?.id === WeightNoteTypes.PRODUCT_ID ||
    props.selectedWeightNote.weightNoteType?.id === WeightNoteTypes.PRODUCT_SAMPLE_ID) ?
    props.storageMethods.find((method) => method.id === CatchRegistrationDefaults.STORAGE_METHOD_FROZEN_ID) :
    props.storageMethods.find((method) => method.id === CatchRegistrationDefaults.STORAGE_METHOD_ICED_ID);
  const defaultDestiny = props.containsExported ?
    props.destinies.find((dest) => dest.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID) :
    props.destinies.find((dest) => dest.id === CatchRegistrationDefaults.DESTINY_PROCESSING_ID);
  const maxWeight = props.selectedWeightNote?.weights?.reduce((max, weight) =>
    weight.id > max.id ? weight : max, props.selectedWeightNote.weights[0]);
  const lastDestiny = props.destinies.find((dest) => dest.id === maxWeight?.destiny.id);

  useEffect(() => {
    if (props.weight) {
      setSelectedFishingArea(props.fishAreas.find((area) => area.id === props.weight?.fishingArea.id));
      setSelectedStorageMethod(props.storageMethods.find((method) => method.id === props.weight?.storageMethod.id));
      setSelectedDestiny(props.destinies.find((dest) => dest.id === props.weight?.destiny.id));
      setSelectedStock(props.fishingStocks.find((stock) => stock.id === props.weight?.fishingStock.id));
      setSelectedCondition(props.conditions.find((condition) => condition.id === props.weight?.condition.id));
      setIsUnderKilogram(props.weight?.underKilogram ?? false);
    } else {
      // Set the default autocomplete values.
      setSelectedFishingArea(defaultFishingArea);
      setSelectedStorageMethod(defaultStorageMethod);
      // Set destiny as last registered destiny, if no match use default.
      setSelectedDestiny(lastDestiny ? lastDestiny : defaultDestiny);
      setSelectedStock(undefined);
      setSelectedCondition(undefined);
      props.handleFormChange({
        fishingAreaId: defaultFishingArea?.id,
        storageMethodId: defaultStorageMethod?.id,
        destinyId: lastDestiny ? lastDestiny?.id : defaultDestiny?.id
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDestiny, defaultFishingArea, defaultStorageMethod, props.weight]);

  const handleCheckBoxClick = () => {
    if (!isUnderKilogramDisabled) {
      setIsUnderKilogram((prevState) => !prevState);
      props.handleFormChange({ isUnderKilogram: !isUnderKilogram });
    }
  };

  const handleFishingAreaChange = (selectedValue: FishingArea | undefined) => {
    setSelectedFishingArea(selectedValue);
    const updatedForm: PostCatchBody = { fishingAreaId: selectedValue?.id };
    props.handleFormChange(updatedForm);
  };

  const handleStorageMethodChange = (selectedValue: StorageMethod | undefined) => {
    setSelectedStorageMethod(selectedValue);
    const updatedForm: PostCatchBody = { storageMethodId: selectedValue?.id };
    props.handleFormChange(updatedForm);
  };

  const handleDestinyChange = (selectedValue: Destiny | undefined) => {
    setSelectedDestiny(selectedValue);
    const updatedForm: PostCatchBody = { destinyId: selectedValue?.id };
    props.handleFormChange(updatedForm);
  };

  const handleFishingStockChange = (selectedValue: FishingStock | undefined) => {
    setSelectedStock(selectedValue);
    // Check if the previous Autocomplete components have values
    if (selectedFishingArea && selectedStorageMethod && selectedDestiny && selectedValue) {
      // Check if there is only one allowed combination
      const filteredCombinations = props.allowedCombinations?.filter((combination) => {
        return (
          combination.fishingAreaId === selectedFishingArea.id &&
          combination.storageMethodId === selectedStorageMethod.id &&
          combination.destinyId === selectedDestiny.id &&
          combination.fishStockId === selectedValue.id
        );
      });

      if (filteredCombinations?.length === 1) {
        const allowedCombination = filteredCombinations[0];
        const condition = props.conditions.find((condition) => condition.id === allowedCombination.conditionId);
        if (condition) {
          handleConditionChange(condition);
        }
      }
    }
    const updatedForm: PostCatchBody = {
      fishStockId: selectedValue?.id
    };
    props.handleFormChange(updatedForm);
  };

  const handleConditionChange = (selectedValue: Condition | undefined) => {
    setSelectedCondition(selectedValue);
    const updatedForm: PostCatchBody = {
      conditionId: selectedValue?.id
    };
    props.handleFormChange(updatedForm);
  };

  const handleInputFishingAreaChange = (value: string) => {
    setFishingAreaInputValue(value);
    if (value === '') {
      setSelectedFishingArea(undefined);
      const updatedForm: PostCatchBody = {
        fishingAreaId: undefined
      };
      props.handleFormChange(updatedForm);
    }
  };

  const handleInputDestinyChange = (value: string) => {
    setDestinyInputValue(value);
    if (value === '') {
      setSelectedDestiny(undefined);
      const updatedForm: PostCatchBody = {
        destinyId: undefined
      };
      props.handleFormChange(updatedForm);
    }
  };

  const handleInputStorageMethodChange = (value: string) => {
    setStorageMethodInputValue(value);
    if (value === '') {
      setSelectedStorageMethod(undefined);
      const updatedForm: PostCatchBody = {
        storageMethodId: undefined
      };
      props.handleFormChange(updatedForm);
    }
  };

  const handleInputFishingStockChange = (value: string) => {
    setStockInputValue(value);
    if (value === '') {
      setSelectedStock(undefined);
      const updatedForm: PostCatchBody = {
        fishStockId: undefined
      };
      props.handleFormChange(updatedForm);
    }
  };

  const handleInputConditionChange = (value: string) => {
    setConditionInputValue(value);
    if (value === '') {
      setSelectedCondition(undefined);
      const updatedForm: PostCatchBody = {
        conditionId: undefined
      };
      props.handleFormChange(updatedForm);
    }
  };

  const filterAndSortOptions = (options: FishingStock[], inputValue: string) => {
    // Normalize the input value to handle Icelandic characters.
    // In old GAFL users were custom to input "o" and get "ó" options.
    // This hacky regex removes all accents from characters.
    const inputValueNormalized = inputValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    // Options that start with the normalized input
    const matchingStart = options.filter((option) =>
      option.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().startsWith(inputValueNormalized)
    );
    // Options that include the normalized input
    const matchingIncludes = options.filter((option) =>
      option.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(inputValueNormalized)
    );
    // Create a Set to store unique options
    const uniqueOptions = new Set<FishingStock>(matchingStart);
    // Add matchingInclude options to the Set (duplicates will be automatically removed)
    matchingIncludes.forEach((option) => uniqueOptions.add(option));
    // Convert the Set back to an array
    const sortedOptions = Array.from(uniqueOptions);
    return sortedOptions;
  };

  function filterStocksBasedOnGear() {
    return props.fishingStocks.filter(stock =>
      (props.gears?.find(gear => gear.id === props.selectedWeightNote.gear.id)?.forbiddenStocks || [])
        .every(forbiddenStock => forbiddenStock.id !== stock.id)
    );
  }

  const filterOptions = (optionName: 'fishingArea' | 'destiny' | 'storageMethod' | 'fishingStock' | 'condition') => {
    // Filter options based on other selected values
    if (optionName === 'fishingArea') {
      const matchingFishingAreas: FishingArea[] = [];
      const fishingAreaIds: Set<number> = new Set();
      props.allowedCombinations?.forEach((combination) => {
        if (
          (!selectedStorageMethod || combination.storageMethodId === selectedStorageMethod.id) &&
          (!selectedDestiny || combination.destinyId === selectedDestiny.id) &&
          (!selectedStock || combination.fishStockId === selectedStock.id) &&
          (!selectedCondition || combination.conditionId === selectedCondition.id) &&
          (!isUnderKilogram || combination.isUnderKilogram === isUnderKilogram)
        ) {
          const fishingAreaId = combination.fishingAreaId;
          if (!fishingAreaIds.has(fishingAreaId)) {
            fishingAreaIds.add(fishingAreaId);
            const fishingArea = props.fishAreas.find((area) => area.id === fishingAreaId);
            if (fishingArea) {
              matchingFishingAreas.push(fishingArea);
            }
          }
        }
      });
      return matchingFishingAreas;
    }
    if (optionName === 'destiny') {
      const matchingDestinies: Destiny[] = [];
      const destinyIds: Set<number> = new Set();
      props.allowedCombinations?.forEach((combination) => {
        if (
          (!selectedFishingArea || combination.fishingAreaId === selectedFishingArea.id) &&
          (!selectedStorageMethod || combination.storageMethodId === selectedStorageMethod.id) &&
          (!selectedStock || combination.fishStockId === selectedStock.id) &&
          (!selectedCondition || combination.conditionId === selectedCondition.id) &&
          (!isUnderKilogram || combination.isUnderKilogram === isUnderKilogram)
        ) {
          const destinyId = combination.destinyId;
          if (!destinyIds.has(destinyId)) {
            destinyIds.add(destinyId);
            const destiny = props.destinies.find((dest) => dest.id === destinyId);
            if (destiny) {
              matchingDestinies.push(destiny);
            }
          }
        }
      });
      return matchingDestinies;
    }
    if (optionName === 'storageMethod') {
      const matchingStorageMethods: StorageMethod[] = [];
      const storageMethodIds: Set<number> = new Set();
      props.allowedCombinations?.forEach((combination) => {
        if (
          (!selectedFishingArea || combination.fishingAreaId === selectedFishingArea.id) &&
          (!selectedDestiny || combination.destinyId === selectedDestiny.id) &&
          (!selectedStock || combination.fishStockId === selectedStock.id) &&
          (!selectedCondition || combination.conditionId === selectedCondition.id) &&
          (!isUnderKilogram || combination.isUnderKilogram === isUnderKilogram)
        ) {
          const storageMethodId = combination.storageMethodId;
          if (!storageMethodIds.has(storageMethodId)) {
            storageMethodIds.add(storageMethodId);
            const storageMethod = props.storageMethods.find((method) => method.id === storageMethodId);
            if (storageMethod) {
              matchingStorageMethods.push(storageMethod);
            }
          }
        }
      });
      return matchingStorageMethods;
    }
    if (optionName === 'fishingStock') {
      const matchingFishingStocks: FishingStock[] = [];
      const fishingStockIds: Set<number> = new Set();
      props.allowedCombinations?.forEach((combination) => {
        if (
          (!selectedFishingArea || combination.fishingAreaId === selectedFishingArea.id) &&
          (!selectedStorageMethod || combination.storageMethodId === selectedStorageMethod.id) &&
          (!selectedDestiny || combination.destinyId === selectedDestiny.id) &&
          (!selectedCondition || combination.conditionId === selectedCondition.id) &&
          (!isUnderKilogram || combination.isUnderKilogram === isUnderKilogram)
        ) {
          const fishingStockId = combination.fishStockId;
          if (!fishingStockIds.has(fishingStockId)) {
            fishingStockIds.add(fishingStockId);
            const fishingStock = filterStocksBasedOnGear().find((stock) => stock.id === fishingStockId);
            if (fishingStock) {
              matchingFishingStocks.push(fishingStock);
            }
          }
        }
      });
      return matchingFishingStocks;
    }
    if (optionName === 'condition') {
      const matchingConditions: Condition[] = [];
      const conditionIds: Set<number> = new Set();
      props.allowedCombinations?.forEach((combination) => {
        if (
          (!selectedFishingArea || combination.fishingAreaId === selectedFishingArea.id) &&
          (!selectedStorageMethod || combination.storageMethodId === selectedStorageMethod.id) &&
          (!selectedDestiny || combination.destinyId === selectedDestiny.id) &&
          (!selectedStock || combination.fishStockId === selectedStock.id) &&
          (!isUnderKilogram || combination.isUnderKilogram === isUnderKilogram)
        ) {
          const conditionId = combination.conditionId;
          if (!conditionIds.has(conditionId)) {
            conditionIds.add(conditionId);
            const condition = props.conditions.find((cond) => cond.id === conditionId);
            if (condition) {
              matchingConditions.push(condition);
            }
          }
        }
      });
      return matchingConditions;
    }
    return [];
  };

  return (
    <CatchRegistrationCatchWrapper>
      <Autocomplete
        sx={{ width: '100%' }}
        disablePortal
        disabled={props.selectedWeightNote.isClosed ? true : props.disabled ? true : false}
        options={filterOptions('fishingArea')}
        filterOptions={(options, state) => filterAndSortOptions(options, state.inputValue)}
        autoHighlight
        autoSelect={fishingAreaInputValue !== ''}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        value={selectedFishingArea || null}
        onChange={(event, value) => handleFishingAreaChange(value || undefined)}
        onInputChange={(event, value) => handleInputFishingAreaChange(value)}
        slotProps={{
          popper: {
            disablePortal: false,
          }
        }}
        renderInput={(params) =>
          <TextField
            label="Veiðisvæði"
            color="primary"
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon color='primary' />
                </InputAdornment>
              )
            }}
          />}
      />
      <Autocomplete
        sx={{ width: '100%' }}
        disablePortal
        disabled={props.selectedWeightNote.isClosed ? true : props.disabled ? true : false}
        autoHighlight
        autoSelect={storageMethodInputValue !== ''}
        options={filterOptions('storageMethod')}
        filterOptions={(options, state) => filterAndSortOptions(options, state.inputValue)}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        value={selectedStorageMethod || null}
        onChange={(event, value) => handleStorageMethodChange(value || undefined)}
        onInputChange={(event, value) => handleInputStorageMethodChange(value)}
        slotProps={{
          popper: {
            disablePortal: false,
          }
        }}
        renderInput={(params) =>
          <TextField
            label="Geymsluaðferð"
            color="primary"
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <AcUnitIcon color='primary' />
                </InputAdornment>
              )
            }}
          />}
      />

      <Autocomplete
        sx={{ width: '100%' }}
        disablePortal
        disabled={props.selectedWeightNote.isClosed ? true : props.disabled ? true : false}
        autoHighlight
        autoSelect={destinyInputValue !== ''}
        options={filterOptions('destiny')}
        filterOptions={(options, state) => filterAndSortOptions(options, state.inputValue)}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        value={selectedDestiny || null}
        onChange={(event, value) => handleDestinyChange(value || undefined)}
        onInputChange={(event, value) => handleInputDestinyChange(value)}
        slotProps={{
          popper: {
            disablePortal: false,
          }
        }}
        renderInput={(params) =>
          <TextField
            label="Afdrif"
            color="primary"
            helperText={(props.containsExported && !props?.selectedWeightNote?.isClosed) ? "Aflaskráning merkt 'Útflutningur' fannst á vigtarnótu." : ""}
            error={props.containsExported && selectedDestiny?.id !== CatchRegistrationDefaults.DESTINY_EXPORTED_ID}
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <ImportExportIcon color='primary' />
                </InputAdornment>
              )
            }}
          />}
      />

      <FormControl sx={{ width: '100%' }}>
        <InputLabel shrink id="under-one-kg">Undirmál</InputLabel>
        <Select
          disabled={props.selectedWeightNote.isClosed || isUnderKilogramDisabled}
          onChange={() => handleCheckBoxClick()}
          value={isUnderKilogram ? 1 : 0}
          label="Undirmál"
          inputProps={{
            id: "under-one-kg",
          }}
          startAdornment={
            <InputAdornment position="start">
              <AlignVerticalBottomIcon color='primary' />
            </InputAdornment>
          }
        >
          <MenuItem value={0}>Nei</MenuItem>
          <MenuItem value={1}>Já</MenuItem>
        </Select>
      </FormControl>

      <Autocomplete
        sx={{ width: '100%' }}
        disablePortal
        autoSelect={stockInputValue !== ''}
        disabled={props.selectedWeightNote.isClosed ? true : props.disabled ? true : false}
        autoHighlight
        options={filterOptions('fishingStock')}
        filterOptions={(options, state) => filterAndSortOptions(options, state.inputValue)}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        value={selectedStock || null}
        onChange={(event, value) => handleFishingStockChange(value || undefined)}
        onInputChange={(event, value) => handleInputFishingStockChange(value)}
        slotProps={{
          popper: {
            disablePortal: false,
          }
        }}
        renderInput={(params) =>
          <TextField
            inputRef={inputRef}
            autoFocus={!props.weight && !isMobile && !isTablet}
            label="Veiðistofn"
            color="primary"
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SetMealIcon color='primary' />
                </InputAdornment>
              )
            }}
          />}
      />

      <Autocomplete
        sx={{ width: '100%' }}
        disablePortal
        disabled={props.selectedWeightNote.isClosed ? true : props.disabled ? true : false}
        autoHighlight
        autoSelect={conditionInputValue !== ''}
        options={filterOptions('condition')}
        filterOptions={(options, state) => filterAndSortOptions(options, state.inputValue)}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        value={selectedCondition || null}
        onChange={(event, value) => handleConditionChange(value || undefined)}
        onInputChange={(event, value) => handleInputConditionChange(value)}
        slotProps={{
          popper: {
            disablePortal: false,
          }
        }}
        renderInput={(params) =>
          <TextField
            label="Ástand"
            color="primary"
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SignLanguageIcon color='primary' />
                </InputAdornment>
              )
            }}
          />}
      />

    </CatchRegistrationCatchWrapper>
  );
};

export default CatchRegistrationCatch;