import { styled } from "styled-components";

export const ReWeightingListWrapper = styled.div`
  ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 10px;
      border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 0.5em;
  gap: 1em;
  justify-content: space-between;
  h4 {
    margin-block: 0;
    vertical-align: middle;
  }
  line-height: 2.25em; 
  flex-flow: row wrap;
`;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const Card = styled.div`
    box-shadow: 0 0.375rem 0.75rem rgba(140,152,164,.075);
    --bs-card-spacer-y: 1.3125rem;
    --bs-card-spacer-x: 1.3125rem;
    --bs-card-title-spacer-y: 0.25rem;
    --bs-card-border-width: 0.0625rem;
    --bs-card-border-color: rgba(231, 234, 243, 1);
    --bs-card-border-radius: 0.75rem;
    --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
    --bs-card-inner-border-radius: 0.6875rem;
    --bs-card-cap-padding-y: 1.3125rem;
    --bs-card-cap-padding-x: 1.3125rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    padding: 1em;
    margin: 1em 1em 1em 1em;
`;

export const CardLabel = styled.div`
    text-transform: uppercase;
    color: #8c98a4;
    font-size: 0.8rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
`;

export const CardData = styled.span`
    color: #1e2022;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    display: flex;
    justify-content: space-between;
`;

export const ExporterImporterContainer = styled.span`
    color: #1e2022;
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    gap: 1em;
    flex-direction: column;
`;

export const ListItem = styled.div`
  padding: 1em;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
`;

export const DateFont = styled.div`
  color: grey;
  font-weight: 400;
`;