import { Stack, Typography, Chip, Switch, FormControlLabel, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { FC, useState } from "react";
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import BusinessIcon from '@mui/icons-material/Business';
import GppGoodIcon from '@mui/icons-material/GppGood';
import GppBadIcon from '@mui/icons-material/GppBad';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ButtonWrapper, Card, CardData, DateFont, ExporterImporterContainer, ListItem, ReWeightingListWrapper } from "./ReWeightingList.styled";
import ReWeightingLanding from "../../../../Models/ReWeightingModels/ReWeightingLanding";

interface ReWeightingListProps {
  width: string;
  landings: ReWeightingLanding[];
  selectedLanding: ReWeightingLanding;
  setSelectedLanding: (landing: ReWeightingLanding) => void;
  fetchNextLandings: (pageNumber: number, refetching: boolean) => void;
  loading: boolean;
}

const ReWeightingList: FC<ReWeightingListProps> = (props) => {
  const [showClosedWeightNotes, setShowClosedWeightNotes] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  

  const landingWeightNoteColumns: GridColDef[] = [
    {
      field: 'ship',
      headerName: 'Skip',
      type: 'singleSelect',
      flex: 1,
      colSpan: 42,
      valueGetter: (params) => params.row?.id,
      renderCell: (params) => (
        <ListItem>
          <CardData>
            <ExporterImporterContainer>
              <Stack direction="row" alignItems="center" gap={1}>
                <DirectionsBoatIcon sx={{ fontSize: '15px', color: theme.palette.mode === "dark" ? "white" : undefined }} />
                <Typography variant="body2" style={{ color: theme.palette.text.primary }}>{params.row?.ship?.name ?? ""}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <BusinessIcon sx={{ fontSize: '15px', color: theme.palette.mode === "dark" ? "white" : undefined }} />
                <Typography variant="body2" style={{ color: theme.palette.text.primary }}>{params.row?.harbour?.harbourName ?? ""}</Typography>
              </Stack>
            </ExporterImporterContainer>
            <div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Chip
                  icon={params.row?.weightNote.isClosed ? <GppGoodIcon /> : <GppBadIcon />}
                  color={params.row?.weightNote.isClosed ? 'success' : 'primary'}
                  size="small"
                  sx={{
                    "&.MuiChip-root .MuiChip-icon": {
                      marginRight: "-12px",
                    }
                  }}
                />
              </div>
              <DateFont>
                <Typography variant="body2" style={{ color: theme.palette.text.primary }}>
                  {dayjs(new Date(params.row?.landingStarts)).format('DD.MM.YYYY')}
                </Typography>
              </DateFont>
            </div>
          </CardData>
        </ListItem>
      )
    }
  ];

  const handleFecthMore = () => {
    props.fetchNextLandings(Math.ceil(props.landings?.length / 10) + 1, false);
  };

  return (
    <ReWeightingListWrapper style={{ width: isMobile || isTablet ? '100%' : '25%' }}>
      <Card
        style={{
          margin: isMobile || isTablet ? '0' : '',
          padding: isMobile || isTablet ? '0' : '',
          borderRadius: isMobile || isTablet ? '0' : '0.75rem',
          height: isMobile || isTablet ? '100%' : '',
          backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined
        }}>
        <ButtonWrapper style={{ margin: isMobile || isTablet ? '1em' : '' }}>
          <Typography variant="h6"><b>Endurvigtanir</b></Typography>
          <FormControlLabel
            control={<Switch />}
            checked={showClosedWeightNotes}
            label="Sýna lokaðar"
            onChange={() => setShowClosedWeightNotes(!showClosedWeightNotes)}
          />
        </ButtonWrapper>
        <DataGrid
          rows={showClosedWeightNotes ? props.landings : props.landings.filter(landing => !landing.weightNote.isClosed)}
          columns={landingWeightNoteColumns}
          onRowClick={(row) => {
            props.setSelectedLanding(row.row as ReWeightingLanding);
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            }
          }}
          pageSizeOptions={[100]}
          sx={{
            "&.MuiDataGrid-root": {
              paddingBottom: isMobile || isTablet ? '4em' : '',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&.MuiDataGrid-root .MuiDataGrid-row:focus-within": {
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.info.dark : "#d9ebfc"
            },
            "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
              backgroundColor: theme.palette.mode === "dark" ? theme.palette.info.dark : "#d9ebfc"
            },
            "&.MuiDataGrid-root .MuiDataGrid-virtualScroller": {
              overflowX: 'hidden'
            },
            '& .MuiDataGrid-cell': {
              padding: 0,
            }
          }}
          columnHeaderHeight={0}
          slots={{
            noRowsOverlay: () => (
              <Stack height="90%" alignItems="center" justifyContent="center">
                {props.loading ? 'Sæki...' : 'Engar endurvigtanir fundust.'}
              </Stack>
            )
          }}
          getRowHeight={() => 'auto'}
          disableColumnMenu
          loading={props.loading}
        />
        <IconButton
          style={{ position: 'absolute', bottom: '10%', right: '40%' }}
          onClick={handleFecthMore}
        >
          <KeyboardArrowDownIcon color="primary" />
        </IconButton>
      </Card>
    </ReWeightingListWrapper>
  );
};

export default ReWeightingList;
