import styled from 'styled-components';

export const LandingTableWrapper = styled.div`
    height: 100%;
    width: 100%;
    overflow: auto;

    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    /* scrollbar */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
    
    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgba(255, 255, 255, 0.3);
    }
`;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const Card = styled.div`
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    background-clip: border-box;
    cursor: pointer;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    width: 100%;
    height: 5.5em;
`;

export const CardLabel = styled.div`
    text-transform: uppercase;
    color: rgb(127 138 149);
    font-size: 0.8rem;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.25em;
`;

export const CardData = styled.span`
    color: #1e2022;
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.25;
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.25em;
    padding-top: 0.5em;
`;

export const Ellipsis = styled.div`
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding-top: 0.15em;
`;