import React, { useState, useEffect, FC, Fragment } from 'react';
import { getCatchSumReport } from '../../../../../Services/LondunarkerfiAPIService';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import User from '../../../../../Models/UserModels/User';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/is';
import Ship from '../../../../../Models/ShipModels/Ship';
import Contact from '../../../../../Models/ContactModels/Contact';
import CatchSum from '../../../../../Models/ReportModels/CatchSum';
import ExcelGenerator from '../../../../ExcelGenerator/ExcelGenerator';
import ContactAutoComplete from '../../../../AutoComplete/ContactAutoComplete';
import ShipAutoComplete from '../../../../AutoComplete/ShipAutoComplete';
import { logError } from '../../../../../Helpers/LogError';

interface CatchSumReportsProps {
  user: User;
  toggleOpen: () => void;
}

const CatchSumReport: FC<CatchSumReportsProps> = (props: CatchSumReportsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [catchSum, setCatchSum] = useState<CatchSum[]>([]);
  const [ship, setShip] = useState<Ship | null>(null);
  const [selectedReciever, setSelectedReciever] = useState<Contact | null>(null);
  const [selectedBuyer, setSelectedBuyer] = useState<Contact | null>(null);
  const [selectedSeller, setSelectedSeller] = useState<Contact | null>(null);


  useEffect(() => {
    fetchCathes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, ship, selectedBuyer, selectedReciever, selectedSeller]);

  const fetchCathes = async () => {
    try {
      setLoading(true);
      if (props.user?.userPickedHarbour) {
        const formattedDateFrom = dateFrom?.format('DD.MM.YYYY');
        const formattedDateTo = dateTo?.format('DD.MM.YYYY');
        const response = await getCatchSumReport(
          props.user?.userPickedHarbour,
          formattedDateFrom,
          formattedDateTo,
          ship?.shipRegistrationNumber,
          selectedBuyer?.ssn,
          selectedReciever?.ssn,
          selectedSeller?.ssn);
        setCatchSum(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  const columns: GridColDef[] = [
    { field: 'catchName', headerName: 'Fisktegund', width: 300, resizable: false },
    { field: 'amount', headerName: 'Magn', type: 'number', width: 110, resizable: false },
  ];

  const handleDateFromChange = (date: Dayjs | null) => {
    setDateFrom(date);
  };

  const handleDateToChange = (date: Dayjs | null) => {
    setDateTo(date);
  };

  const handleShipChange = (selectedShip: Ship | null) => {
    setShip(selectedShip);
  };

  const handleBuyerChange = (params: Contact | null) => {
    setSelectedBuyer(params);
  };

  const handleRecieverChange = (params: Contact | null) => {
    setSelectedReciever(params);
  };

  const handleSellerChange = (params: Contact | null) => {
    setSelectedSeller(params);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ExcelGenerator data={catchSum} disabled={loading} fileName='samtolur' />
      </GridToolbarContainer>
    );
  }

  return (
    <Fragment>
      <div style={{ width: '100%', display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
          <DatePicker
            sx={{ flex: '1', minWidth: '15em' }}
            disabled={loading}
            label='Dagsetning frá'
            value={dateFrom}
            onChange={(newValue: Dayjs | null) => handleDateFromChange(dayjs(newValue))}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
          />
          <DatePicker
            sx={{ flex: '1', minWidth: '15em' }}
            disabled={loading}
            label='Dagsetning til'
            value={dateTo}
            onChange={(newValue: Dayjs | null) => handleDateToChange(dayjs(newValue))}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
          />
        </LocalizationProvider>
        <ShipAutoComplete onShipChange={handleShipChange} selectedShip={ship} sx={{ flex: '1', minWidth: '15em' }} autofocus={false} />
        <ContactAutoComplete type='Kaupandi' smallInputField={true} onContactChange={handleBuyerChange} selectedContact={selectedBuyer} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
        <ContactAutoComplete type='Viðtakandi' smallInputField={true} onContactChange={handleRecieverChange} selectedContact={selectedReciever} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
        <ContactAutoComplete type='Seljandi' smallInputField={true} onContactChange={handleSellerChange} selectedContact={selectedSeller} autofocus={false} sx={{ flex: '1', minWidth: '15em' }} />
      </div>
      <div>
        <DataGrid
          rows={catchSum}
          columns={columns}
          loading={loading}
          columnVisibilityModel={{ id: false }}
          autoHeight
          getRowId={(row) => row.catchName}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </Fragment >
  );
};

export default CatchSumReport;
