import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../Services/LondunarkerfiAPIService';
import { AuthService } from '../../Services/AuthService';

const NotFound: FC = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const handleLoginChange = async () => {
      try {
        const userData = await getUser(false);
        if (userData && userData.isReweighingUser) {
          navigate('/reweighting');
        } else {
          navigate('/landings');
        }
      } catch (error) {
        const authService = new AuthService();
        authService.login();
      }
    }
    handleLoginChange();
  }, [navigate]);

  return (<></>);
};

export default NotFound;