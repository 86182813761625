import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { EditLandingModalWrapper, TitleFont, StyledTextField } from './EditLandingModal.styled';
import { Autocomplete, Box, Dialog, IconButton, Input, InputAdornment, Slider, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import Ship from '../../../../Models/ShipModels/Ship';
import { getPossibleTraceIds, patchLanding } from '../../../../Services/LondunarkerfiAPIService';
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/is';
import Landing from '../../../../Models/LandingModels/Landing';
import LandingType from '../../../../Models/LandingModels/LandingType';
import LandingSubmit from '../../../../Models/LandingModels/LandingSubmit';
import CancelIcon from '@mui/icons-material/Cancel';
import CommentIcon from '@mui/icons-material/Comment';
import ArticleIcon from '@mui/icons-material/Article';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LoadingButton } from '@mui/lab';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import User from '../../../../Models/UserModels/User';
import ShipAutoComplete from '../../../../SharedComponents/AutoComplete/ShipAutoComplete';
import TraceIds from '../../../../Models/LandingModels/TraceIds';
import { MobilePaperComponent, PaperComponent } from '../../../../SharedComponents/Paper/CustomPaper';
import { logError } from '../../../../Helpers/LogError';

interface EditLandingModalProps {
  editLandingModalOpen: boolean;
  toggleEditLandingModalOpen: (inputToFocus?: string) => void;
  inputToFocus: string | undefined;
  selectedLanding: Landing;
  selectedWeightNote: WeightNote;
  landingTypes: LandingType[];
  landings: Landing[];
  fetchLandings: (setSelectedLandingId?: number, setSelectedWeightNoteId?: number) => void;
  showSnackbar: (message: string, severity: string) => void;
  user: User;
}

/**
 * Functional component for EditLandingModal.
 * Displays a modal for editing a landing.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a function to fetch landings.
 * @returns {JSX} - Responsible for returning the modal which is used to edit a specific landing.
 */

const EditLandingModal: FC<EditLandingModalProps> = (props: EditLandingModalProps) => {

  const [ship, setShip] = useState<Ship | null>(null);
  const [landingType, setLandingType] = useState({} as LandingType | null);
  const [comment, setComment] = useState('');
  const [landingStarts, setLandingStarts] = useState<Dayjs | null>(dayjs());
  const [patchBody, setPatchBody] = useState<LandingSubmit | undefined>(undefined);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [possibleTraceIds, setPossibleTraceIds] = useState<TraceIds[]>([]);
  const [selectedTraceId, setSelectedTraceId] = useState<TraceIds | null>(null);
  const [traceDateRange, setTraceDateRange] = useState<number>(2);
  const [sliderChangeCommitted, setSliderChangeCommitted] = useState<boolean>(false);
  const [focusedField, setFocusedField] = useState<'none' | 'ship' | 'date' | 'type' | 'traceId' >('none');
  const blurTimerRef = useRef<NodeJS.Timeout | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const MAX_DATE_RANGE = 5;

  useEffect(() => {
    const initiateLandingFormData = async () => {
      if (!props.selectedLanding.id) {
        return;
      }
      setShip(props.selectedLanding.ship);
      setLandingType(props.selectedLanding.landingType);
      setLandingStarts(dayjs(props.selectedLanding.landingStarts));
      setComment(props.selectedLanding.comment ? props.selectedLanding.comment : '');
      const traceId: TraceIds = {
        traceID: props.selectedLanding?.traceID,
        shipRegistrationNumber: props.selectedLanding.ship.shipRegistrationNumber.toString(),
        landingDate: props.selectedLanding?.landingStarts,
        landingId: props.selectedLanding?.id,
        catchData: [],
        bycatchData: [],
        huntingTripInfo: ''
      };
      setSelectedTraceId(traceId);
    }
    initiateLandingFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedLanding]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isFormChanged) {
        event.preventDefault();
        if (submitButtonRef.current && focusedField === 'none') {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isFormChanged, focusedField]);

  const handleBlur = () => {
    if (blurTimerRef.current) {
      clearTimeout(blurTimerRef.current);
    }

    blurTimerRef.current = setTimeout(() => {
      setFocusedField('none');
    }, 100);
  };

  useEffect(() => {
    const getTraceIds = async () => {
      setLoading(true);
      try {
        if (ship?.shipRegistrationNumber && landingStarts) {
          const possibleTraceIds = await getPossibleTraceIds(props.selectedLanding.id, ship?.shipRegistrationNumber, landingStarts?.toString(), traceDateRange);
          setPossibleTraceIds(possibleTraceIds);
        }
        setLoading(false);
      } catch (error) {
        logError(error);
        setLoading(false);
      }
    };

    if (!dayjs(props.selectedLanding.landingStarts).isSame(landingStarts) || ship?.shipRegistrationNumber !== props.selectedLanding.ship.shipRegistrationNumber || sliderChangeCommitted) {
      getTraceIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingStarts, ship?.shipRegistrationNumber, sliderChangeCommitted]);

  const handleTypeChange = (selectedLandingType: LandingType | null) => {
    setLandingType(selectedLandingType);
    setPatchBody(prevPatchBody => ({
      ...prevPatchBody,
      landingTypeId: selectedLandingType?.id
    }));
    setIsFormChanged(false);
    if (selectedLandingType?.id !== props.selectedLanding.landingType.id) {
      setIsFormChanged(true);
    }
  }

  const handleShipChange = (selectedShip: Ship | null) => {
    setShip(selectedShip);
    setPatchBody(prevPatchBody => ({
      ...prevPatchBody,
      shipNumber: selectedShip?.shipRegistrationNumber
    }));
    setIsFormChanged(false);
    if (selectedShip?.shipRegistrationNumber !== props.selectedLanding.ship.shipRegistrationNumber) {
      setIsFormChanged(true);
    }
  }

  const handleCommentChange = (comment: string) => {
    setComment(comment);
    setPatchBody(prevPatchBody => ({
      ...prevPatchBody,
      comment: comment
    }));
    setIsFormChanged(false);
    if (comment !== props.selectedLanding.comment) {
      setIsFormChanged(true);
    }
  }

  const handleLandingStartsChange = (landingStarts: Dayjs | null) => {
    setLandingStarts(landingStarts);
    setPatchBody(prevPatchBody => ({
      ...prevPatchBody,
      landingStarts: landingStarts?.toDate()
    }));
    setIsFormChanged(false);
    if (!landingStarts?.isSame(props.selectedLanding.landingStarts)) {
      setIsFormChanged(true);
    }
  }

  const handleTraceChange = (selectedTraceId: TraceIds | null) => {
    setSelectedTraceId(selectedTraceId);
    setPatchBody(prevPatchBody => ({
      ...prevPatchBody,
      traceId: selectedTraceId ? selectedTraceId.traceID : undefined
    }));
    setIsFormChanged(false);
    if (selectedTraceId?.traceID !== props.selectedLanding.traceID) {
      setIsFormChanged(true);
    }
  }

  const handleEditLanding = () => {
    if (patchBody) {
      setButtonClicked(true);
      if (!ship) {
        return;
      }
      if (!landingType || landingType.id === undefined) {
        return;
      }
      if (!landingStarts || !landingStarts.isValid()) {
        setDateError('shouldDisableDate');
        return;
      }
      editLanding(patchBody);
    }
    else {
      props.showSnackbar('Óvænt villa kom upp!', 'error');
    }
  }

  async function editLanding(editBody: LandingSubmit) {
    try {
      setLoading(true);
      await patchLanding(props.selectedLanding.id, editBody);
      props.showSnackbar('Löndun uppfærð!', 'success');
      props.toggleEditLandingModalOpen();
      props.fetchLandings(props.selectedLanding.id, props.selectedWeightNote.id);
      setIsFormChanged(false);
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const dateErrorMsg = useMemo(() => {
    switch (dateError) {
      case 'maxDate': {
        return 'Löndun má ekki vera í framtíðinni!';
      }
      case 'minDate': {
        return 'Löndun má ekki vera eldri en 30 dagar!';
      }
      case 'invalidDate': {
        return 'Ógild dagsetning!';
      }
      case 'shouldDisableDate': {
        return 'Veldu dagsetningu!';
      }
      default: {
        return '';
      }
    }
  }, [dateError]);

  const handleInputDateRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSliderChangeCommitted(!sliderChangeCommitted);
    const newValue = Number(event.target.value);
    if (newValue > MAX_DATE_RANGE) {
      setTraceDateRange(MAX_DATE_RANGE);
    } else if (newValue < 1) {
      setTraceDateRange(1);
    } else {
      setTraceDateRange(newValue);
    }
  };

  const handleClose = () => {
    setButtonClicked(false);
    setShip(props.selectedLanding.ship);
    setLandingType(props.selectedLanding.landingType);
    setComment(props.selectedLanding.comment ? props.selectedLanding.comment : '');
    setLandingStarts(dayjs(props.selectedLanding.landingStarts));
    setIsFormChanged(false);
    setPatchBody(undefined);
    props.toggleEditLandingModalOpen();
  }

  return (
    <EditLandingModalWrapper>
      <Dialog
        open={props.editLandingModalOpen}
        onClose={!loading ? handleClose : undefined}
        disableEscapeKeyDown={loading}
        PaperComponent={isMobile || isTablet ? MobilePaperComponent : PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >

        <TitleFont id="draggable-dialog-title">
          Breyta löndun
          <IconButton size='large' sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
            <CancelIcon fontSize='large' />
          </IconButton>
        </TitleFont>

        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
          <Autocomplete
            sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
            disablePortal
            disabled={loading}
            options={props.landingTypes}
            getOptionLabel={(option) => option.name || ""}
            value={landingType ? landingType : null}
            onChange={(e, value) => handleTypeChange(value)}
            onFocus={() => setFocusedField('type')}
            onBlur={handleBlur}
            blurOnSelect
            isOptionEqualToValue={(option, value) => option.id === value.id}
            slotProps={{
              popper: {
                disablePortal: false,
              }
            }}
            renderInput={(params) =>
              <StyledTextField
                label="Gerð löndunar"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  required: true,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ArticleIcon />
                    </InputAdornment>
                  )
                }}
                required
                error={buttonClicked && (!landingType || landingType.id === undefined)}
                helperText={buttonClicked && (!landingType || landingType.id === undefined) ? 'Veldu Landanagerð' : ''}
              />}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
            <DatePicker
              disabled={loading}
              label='Löndun hefst'
              sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
              value={landingStarts}
              onChange={(newValue: Dayjs | null) => handleLandingStartsChange(dayjs(newValue))}
              format="DD.MM.YYYY"
              onError={(newError) => setDateError(newError)}
              slotProps={{
                textField: {
                  helperText: dateErrorMsg,
                  error: dateError !== null,
                  required: true,
                },
              }}
              maxDate={dayjs()}
              minDate={dayjs().subtract(30, 'days')}
            />
          </LocalizationProvider>
        </div>

        <ShipAutoComplete
          onShipChange={handleShipChange}
          onFocus={() => setFocusedField('ship')}
          onBlur={handleBlur}
          selectedShip={ship}
          autofocus={props.inputToFocus === 'ship'}
          errorState={props.landings.some(landing => landing.ship?.shipRegistrationNumber === ship?.shipRegistrationNumber) && props.selectedLanding.ship !== ship}
          errorText={props.landings.some(landing => landing.ship?.shipRegistrationNumber === ship?.shipRegistrationNumber) && props.selectedLanding.ship !== ship ? 'Skipið er með aðra löndun opna!' : ''}
        />

        <TextField
          id="outlined-multiline-static"
          label="Seljandi"
          value={ship ?
            ship?.sellerName + "\n" +
            ship?.sellerSSN + "\n" +
            ship?.sellerPlace + "\n" +
            ship?.sellerAddress
            : ''
          }
          multiline
          disabled
          rows={4}
        />

        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: '1em' }}>
          <Autocomplete
            disabled={loading}
            loading={loading}
            noOptionsText="Engar niðurstöður"
            disablePortal
            sx={{ width: isMobile || isTablet ? '100%' : '50%' }}
            options={possibleTraceIds}
            getOptionLabel={(option) => {
              if (option && option.traceID && option.huntingTripInfo) {
                return option.traceID.toString() + " - " + option.huntingTripInfo;
              } else if (option && option.traceID) {
                return option.traceID.toString();
              } else if (option && option.huntingTripInfo) {
                return option.huntingTripInfo;
              } else {
                return "";
              }
            }}
            value={selectedTraceId}
            onChange={(e, value) => handleTraceChange(value)}
            onFocus={() => setFocusedField('traceId')}
            onBlur={handleBlur}
            blurOnSelect
            isOptionEqualToValue={(option, value) => option.traceID === value.traceID}
            slotProps={{
              popper: {
                disablePortal: false,
              }
            }}
            renderInput={(params) =>
              <StyledTextField
                label="Rekjanleiki"
                color="primary"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ContentPasteSearchIcon />
                    </InputAdornment>
                  )
                }}
              />}
          />
          <Box sx={{ width: isMobile || isTablet ? '100%' : '50%' }}>
            <Typography id="date-range-slider" gutterBottom>
              Dagar frá löndun
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1em', width: '100%' }}>
              <DateRangeIcon />
              <Slider
                value={traceDateRange}
                marks
                onChange={(event, newValue) => setTraceDateRange(newValue as number)}
                onChangeCommitted={() => setSliderChangeCommitted(!sliderChangeCommitted)}
                disabled={loading}
                min={1}
                max={MAX_DATE_RANGE}
                aria-labelledby='date-range-slider'
                valueLabelDisplay='auto'
              />
              <Input
                value={traceDateRange}
                onChange={handleInputDateRangeChange}
                size="small"
                inputProps={{
                  step: 1,
                  min: 1,
                  max: MAX_DATE_RANGE,
                  type: 'number',
                  'aria-labelledby': 'date-range-slider',
                }}
              />
            </Box>
          </Box>
        </div>

        <TextField
          disabled={loading}
          label="Athugasemd"
          onChange={e => handleCommentChange(e.target.value)}
          value={comment}
          InputProps={{
            inputProps: {
              maxLength: 100
            },
            startAdornment: <InputAdornment position="start"><CommentIcon /></InputAdornment>,
          }}
        />
        <LoadingButton
          ref={submitButtonRef}
          size="small"
          disabled={
            !isFormChanged ||
            props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID) ||
            !ship ||
            !landingType ||
            !landingStarts?.isValid() ||
            props.landings.some(landing => landing.ship?.shipRegistrationNumber === ship?.shipRegistrationNumber && landing.id !== props.selectedLanding.id)
          }
          onClick={handleEditLanding}
          loading={loading}
          variant="contained"
        >
          <span>Uppfæra löndun</span>
        </LoadingButton>
      </Dialog>
    </EditLandingModalWrapper>
  );
}

export default EditLandingModal;