const CatchRegistrationDefaults = {
  FISHING_AREA_ICELAND_ID: 1,
  DESTINY_PROCESSING_ID: 1,
  DESTINY_EXPORTED_ID: 15,
  STORAGE_METHOD_ICED_ID: 2,
  STORAGE_METHOD_FROZEN_ID: 4,
  TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID: 1,
  TUB_OR_BOX_DEDUCTION_TYPE_ID: 2,
  PACKAGES_DEDUCTION_TYPE_ID: 3,
  FISHING_STOCK_SCALLOPS_ID: 41,
}
export default CatchRegistrationDefaults;