import React, { FC, useEffect, useRef, useState } from 'react';
import { CatchRegistrationFormHeader, CatchRegistrationFormBody, CatchRegistrationFormWrapper, RowContainer, ChildWrapper, ChildData, MainRowContainer } from './CatchRegistrationModal.styled';
import { Button, Avatar, Box, Step, StepContent, StepLabel, Stepper, Typography, Dialog, DialogContent, useTheme, IconButton, useMediaQuery, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalculateIcon from '@mui/icons-material/Calculate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircleIcon from '@mui/icons-material/Circle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import User from '../../../../Models/UserModels/User';
import PostCatchBody from '../../../../Models/CatchRegistrationModels/PostCatchBody';
import { patchWeight, postDeduction, postWeight } from '../../../../Services/LondunarkerfiAPIService';
import CatchRegistrationItem from '../../../../Models/CatchRegistrationModels/CatchRegistration';
import CatchRegistrationCatch from './CatchRegistrationCatch/CatchRegistrationCatch';
import CatchRegistrationWeight from './CatchRegistrationWeight/CatchRegistrationWeight';
import CatchRegistrationDeduction from './CatchRegistrationDeduction/CatchRegistrationDeduction';
import Vehicle from '../../../../Models/CatchRegistrationModels/Vehicle';
import WeightNote from '../../../../Models/WeightNoteModels/WeightNote';
import { LoadingButton } from '@mui/lab';
import CatchCombination from '../../../../Models/CatchRegistrationModels/CatchCombination';
import FishingArea from '../../../../Models/CatchRegistrationModels/FishingArea';
import StorageMethod from '../../../../Models/CatchRegistrationModels/StorageMethod';
import Destiny from '../../../../Models/CatchRegistrationModels/Destiny';
import FishingStock from '../../../../Models/CatchRegistrationModels/FishingStock';
import Condition from '../../../../Models/CatchRegistrationModels/Condition';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Gear from '../../../../Models/WeightNoteModels/Gear';
import DeductionType from '../../../../Models/CatchRegistrationModels/DeductionType';
import WeightNoteTypes from '../../../../Constants/WeightNoteTypes';
import CatchDeduction from '../../../../Models/CatchRegistrationModels/CatchDeduction';
import PostDeductionBody from '../../../../Models/CatchRegistrationModels/PostDeductionBody';
import CatchRegistrationProductCalcModal from './CatchRegistrationProductCalc/CatchRegistrationProductCalcModal';
import { MobilePaperComponent, PaperComponent } from '../../../../SharedComponents/Paper/CustomPaper';
import CatchRegistrationDefaults from '../../../../Constants/CatchRegistrationDefaults';
import { logError } from '../../../../Helpers/LogError';
import { useConfirm } from 'material-ui-confirm';

interface CatchRegistrationModalProps {
  weightItem: CatchRegistrationItem | undefined;
  setNewWeightItem: (weightId: number, formData?: PostCatchBody) => Promise<void>;
  resetWeightItem: () => void;
  selectedCarouselIndex: number | undefined;
  sortedWeights: CatchRegistrationItem[] | undefined;
  setIndex: (index: number | undefined) => void;
  open: boolean;
  catchCombinations: CatchCombination[] | null;
  fishAreas: FishingArea[];
  storageMethods: StorageMethod[];
  destinies: Destiny[];
  fishingStocks: FishingStock[];
  conditions: Condition[];
  vehicles: Vehicle[];
  gears: Gear[];
  deductionTypes: DeductionType[]
  selectedWeightNote: WeightNote;
  showSnackbar: (message: string, severity: string) => void;
  toggleCatchOpen: () => void;
  user: User;
}

/**
 * Functional component for CatchRegistrationModal.
 * Displays the modal for catch registration.
 * @param props 
 * - takes in various values used for dropDown values.
 * - takes in the selected landing and the selected weightNote.
 * - takes in a function to fetch catches.
 * - takes in a function to set the next catch item.
 * - takes in a function to set the previous catch item.
 * @returns {JSX} - Responsible for returning the modal which is used to add, edit and view catches.
 * should render the following components:
 * - CatchRegistrationCatch
 * - CatchRegistrationWeight
 * - CatchRegistrationVehicle
 * - CatchRegistrationDeduction
 */

const CatchRegistrationModal: FC<CatchRegistrationModalProps> = (props) => {
  const [formData, setFormData] = useState<PostCatchBody>(Object);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentTotalWeight, setCurrentTotalWeight] = useState<number>(0);
  const [tempDeductions, setTempDeductions] = useState<CatchDeduction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [productModalOpen, setProductModalOpen] = useState<boolean>(false);
  const [deductionModalOpen, setDeductionModalOpen] = useState<boolean>(false);
  const confirm = useConfirm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const F8_KEY_CODE = 119;

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !deductionModalOpen && !productModalOpen) {
        event.preventDefault();
        if (submitButtonRef.current) {
          submitButtonRef.current.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [deductionModalOpen, productModalOpen]);

  useEffect(() => {
    // Keyboard shortcuts
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const keyboardNewCatch = (e: any) => {
      if ((e.keyCode) === F8_KEY_CODE) {
        e.preventDefault();
        handleNew();
      }
    };
    window.addEventListener('keydown', keyboardNewCatch);
    return () => {
      window.removeEventListener('keydown', keyboardNewCatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.weightItem) {
      setCurrentTotalWeight(props.weightItem.quantity);
    } else {
      setCurrentTotalWeight(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.weightItem]);

  const toggleDeductionModalOpen = () => {
    setDeductionModalOpen(!deductionModalOpen);
  };

  const handleSubmit = async () => {
    if (formData) {
      try {
        setLoading(true);
        const body: PostCatchBody = {
          isFromReWeighter: false,
          vehicleId: formData.vehicleId,
          vehicleDeduction: formData.vehicleDeduction,
          fishStockId: formData.fishStockId,
          fishingAreaId: formData.fishingAreaId,
          conditionId: formData.conditionId,
          storageMethodId: formData.storageMethodId,
          destinyId: formData.destinyId,
          isUnderKilogram: formData.isUnderKilogram,
          weight: formData.weight,
          sampleWeight: formData.sampleWeight,
          deduction: formData.deduction,
          icePercentage: formData.icePercentage,
          userComment: formData.userComment
        };
        const vehicleId = tempDeductions.find(deduction => deduction.deductionTypeId === CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID)?.vehicleId;
        const vehicleDeduction = tempDeductions.find(deduction => deduction.deductionTypeId === CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID)?.weight;
        if (vehicleId && vehicleDeduction) {
          body.vehicleId = vehicleId;
          body.vehicleDeduction = vehicleDeduction;
        }
        const newWeightItem = await postWeight(body, props.selectedWeightNote.id);
        if (tempDeductions.length > 0) {
          tempDeductions.forEach(async (deduction) => {
            // We don't want to post the vehicle deduction as a car deduction, as it is already registered as a vehicle deduction.
            if (deduction.deductionTypeId !== CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID) {
              const newDeduction: PostDeductionBody = {
                harbourId: props.user?.userPickedHarbour ?? 0,
                typeId: deduction.deductionTypeId,
                unit: deduction.unit,
                quantity: deduction.weight
              };
              await postDeduction(newDeduction, newWeightItem.data);
            }
          });
        }
        await props.setNewWeightItem(newWeightItem.data);
        handleClose();
        props.showSnackbar('Vigtun skráð!', 'success');
        setLoading(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catch (error: any) {
        setLoading(false);
        if (error.response && error.response.data) {
          const errorMsg = error.response.data;
          props.showSnackbar(errorMsg, 'error');

        }
        else {
          logError(error);
          props.showSnackbar('Óvænt villa kom upp!', 'error');
        }
      }
      setFormData({});
    }
  };

  const handleUpdate = async () => {
    if (formData && props.weightItem) {
      if (!formData.shouldClearVehicle) {
        formData.shouldClearVehicle = false;
      }
      try {
        setLoading(true);
        await patchWeight(props.weightItem?.id, formData);
        await props.setNewWeightItem(props.weightItem.id);
        props.showSnackbar('Vigtun uppfærð!', 'success');
        handleClose();
        setLoading(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catch (error: any) {
        setLoading(false);
        if (error.response && error.response.data) {
          const errorMsg = error.response.data;
          props.showSnackbar(errorMsg, 'error');
        }
        else {
          logError(error);
          props.showSnackbar('Óvænt villa kom upp!', 'error');
        }
      }
      setFormData({});
    }
  };

  const handleFormChange = (updatedFormData: PostCatchBody) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      ...updatedFormData
    }));
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSelectStep = (index: number) => {
    setActiveStep(index);
  };

  const getTotalWeight = (updatedFormData: PostCatchBody) => {
    if (props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID && props.weightItem?.sampleWeight) {
      return props.weightItem?.quantity;
    }
    if (!props.weightItem) { // User is creating
      const tempVehicleDeduction = tempDeductions.find((deduction) => deduction.deductionTypeId === CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID);
      const percentage = (updatedFormData.icePercentage || 0) / 100;
      const deduction = updatedFormData.deduction || 0;
      const tempDeduction = tempDeductions.reduce((acc, curr) => {
        return curr.deductionTypeId !== CatchRegistrationDefaults.TRANSPORT_VEHICLE_DEDUCTION_TYPE_ID ? acc + curr.quantity : acc;
      }, 0);
      const vehicleDeduction = tempVehicleDeduction?.weight || 0;
      const weight = props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID ? updatedFormData.sampleWeight || 0 : updatedFormData.weight || 0;
      const netWeight = weight - vehicleDeduction - deduction - tempDeduction;
      const percentageAmount = Math.round(netWeight * percentage);
      return netWeight - percentageAmount;

    } else { // User is editing
      const percentage = (updatedFormData.icePercentage || props.weightItem.icePercentage || 0) / 100;
      const deduction = updatedFormData.deduction || props.weightItem.deduction || 0;
      const vehicleDeduction = updatedFormData.vehicleDeduction || props.weightItem.carDeduction || 0;
      const weight = props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID ? updatedFormData.sampleWeight || props.weightItem.sampleWeight || 0 : updatedFormData.weight || props.weightItem.weight || 0;
      const netWeight = weight - vehicleDeduction - deduction;
      const percentageAmount = Math.round(netWeight * percentage);
      return netWeight - percentageAmount;
    }
  };

  useEffect(() => {
    setCurrentTotalWeight(getTotalWeight(formData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, props.weightItem, props.weightItem?.deductions, tempDeductions]);

  const closeHandler = () => {
    if (!loading) {
      if (props.weightItem) {
        return Object.keys(formData)?.length > 0 ? confirmClose() : handleClose();
      } else {
        // form initializes with 3 formData keys. If there are more keys, the user has made changes.
        return Object.keys(formData)?.length > 3 ? confirmClose() : handleClose();
      }
    }
    return undefined;
  };

  const confirmClose = () => {
    confirm({
      title: 'Villtu loka glugganum?',
      description: `Þú ert með óvistaðar breytingar.`,
      confirmationText: 'Loka',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => handleClose())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  };

  const handleClose = () => {
    setFormData({});
    setActiveStep(0);
    handleTempDeductionChange([]);
    props.toggleCatchOpen();
  };

  const handleNew = () => {
    setFormData({});
    setActiveStep(0);
    handleTempDeductionChange([]);
    props.resetWeightItem();
  };

  const handleTempDeductionChange = (deductions: CatchDeduction[]) => {
    setTempDeductions(deductions)
  };

  const handleProductCalc = () => {
    setProductModalOpen(!productModalOpen);
  };

  const formDataChanges = Object.keys(formData);
  const missingRequiredFieldsInEditState = (
    (formDataChanges.includes('storageMethodId') && !formData.storageMethodId) ||
    (formDataChanges.includes('fishingAreaId') && !formData.fishingAreaId) ||
    (formDataChanges.includes('fishStockId') && !formData.fishStockId) ||
    (formDataChanges.includes('destinyId') && !formData.destinyId) ||
    (formDataChanges.includes('conditionId') && !formData.conditionId)
  );
  const conditionalButtons = () => {
    if (props.weightItem) {
      return (
        <div style={{ width: '100%', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', justifyContent: 'space-between', marginTop: '1em', gap: '1em' }}>
          <Tooltip title='F8' arrow>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleNew}>
              <span>Ný aflaskráning</span>
            </Button>
          </Tooltip>
          <LoadingButton
            fullWidth
            size="small"
            onClick={handleUpdate}
            loading={loading}
            disabled={
              loading ||
              props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID) ||
              Object.keys(formData)?.length === 0 ||
              getTotalWeight({ ...props.weightItem, ...formData }) <= 0 ||
              missingRequiredFieldsInEditState
            }
            endIcon={<SaveIcon />}
            color='primary'
            variant="contained"
            ref={submitButtonRef}
          >
            <span>Uppfæra</span>
          </LoadingButton>
        </div>
      );
    }

    return (
      <div style={{ marginTop: '1em', textAlign: 'right' }}>
        <LoadingButton
          fullWidth={isMobile || isTablet}
          size="small"
          onClick={handleSubmit}
          loading={loading}
          disabled={
            loading ||
            props.user?.role.id === Number(process.env.REACT_APP_ROLE_READ_ID) ||
            !formData.fishStockId ||
            !formData.fishingAreaId ||
            !formData.storageMethodId ||
            !formData.destinyId ||
            !formData.conditionId ||
            (props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID ? !formData.sampleWeight || formData.sampleWeight <= 0 : !formData.weight || formData.weight <= 0) ||
            getTotalWeight(formData) <= 0
          }
          endIcon={<CheckIcon />}
          color='success'
          variant="contained"
          ref={submitButtonRef}
        >
          <span>Staðfesta</span>
        </LoadingButton>
      </div>
    );
  }

  const steps = [
    {
      label: 'Afli',
      inputFields: (
        <CatchRegistrationCatch
          disabled={loading}
          handleFormChange={handleFormChange}
          allowedCombinations={props.catchCombinations}
          weight={props.weightItem}
          selectedWeightNote={props.selectedWeightNote}
          fishAreas={props.fishAreas}
          storageMethods={props.storageMethods}
          destinies={props.destinies}
          fishingStocks={props.fishingStocks}
          conditions={props.conditions}
          gears={props.gears}
          containsExported={props.selectedWeightNote.weights?.some(weight => weight.destiny?.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID) ?? false}
        />
      )
    },
    {
      label: 'Vigtun',
      inputFields: (
        <CatchRegistrationWeight
          disabled={loading}
          handleFormChange={handleFormChange}
          weightItem={props.weightItem}
          selectedWeightNote={props.selectedWeightNote}
          isExported={
            formData.destinyId === CatchRegistrationDefaults.DESTINY_EXPORTED_ID ||
            (props.weightItem?.destiny?.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID && !formData.destinyId)
          }
          stockIsScallops={
            formData.fishStockId === CatchRegistrationDefaults.FISHING_STOCK_SCALLOPS_ID ||
            (props.weightItem?.fishingStock?.id === CatchRegistrationDefaults.FISHING_STOCK_SCALLOPS_ID && !formData.fishStockId)
          }
        />
      )
    },
    {
      label: 'Frádráttur',
      inputFields: (
        <CatchRegistrationDeduction
          deductionModalOpen={deductionModalOpen}
          toggleDeductionModalOpen={toggleDeductionModalOpen}
          selectedWeightNote={props.selectedWeightNote}
          weightItem={props.weightItem}
          user={props.user}
          setNewWeightItem={props.setNewWeightItem}
          deductionTypes={props.deductionTypes}
          showSnackbar={props.showSnackbar}
          tempDeductions={tempDeductions}
          setTempDeductions={handleTempDeductionChange}
          vehicles={props.vehicles}
          formData={formData}
        />
      )
    }
  ];

  const handleCarouselChange = (newIndex: number) => {
    props.setIndex(newIndex);
    setFormData({});
  };

  return (
    <Dialog
      open={props.open}
      onClose={closeHandler}
      disableEscapeKeyDown={loading}
      PaperComponent={isMobile ? MobilePaperComponent : PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth='lg'
      sx={{
        '.MuiPaper-root': {
          padding: 0,
          width: 'auto'
        },
      }}
    >
      {props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID && props.weightItem && productModalOpen && (
        <CatchRegistrationProductCalcModal
          handleFormChange={handleFormChange}
          disabled={props.selectedWeightNote.isClosed}
          selectedWeightNote={props.selectedWeightNote}
          weightItem={props.weightItem}
          showSnackbar={props.showSnackbar}
          deductions={props.weightItem?.deductions ? props.weightItem.deductions : []}
          setWeightItem={props.setNewWeightItem}
          open={productModalOpen}
          toggleOpen={() => setProductModalOpen(!productModalOpen)}
        />
      )}
      {/** Catch registration data form */}
      <CatchRegistrationFormWrapper>
        <CatchRegistrationFormHeader id="draggable-dialog-title">
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignItems: 'center', marginTop: '0.5em' }}>
            <div style={{ flex: 1, width: '100%' }}>
              <Carousel
                autoFocus
                useKeyboardArrows
                labels={{ leftArrow: 'Fyrri aflaskráning', rightArrow: 'Næsta aflaskráning', item: 'Vigtun' }}
                autoPlay={false}
                infiniteLoop
                showStatus={false}
                showThumbs={false}
                showIndicators={!isMobile && !isTablet}
                selectedItem={props.selectedCarouselIndex}
                onChange={handleCarouselChange}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  const defStyle = { color: "#CCCCCC", cursor: "pointer", padding: "0.3em", width: '50%' };
                  const style = isSelected && props.weightItem
                    ? { ...defStyle, color: "black" }
                    : { ...defStyle };
                  return (
                    <span
                      style={style}
                      onClick={(e) => {
                        onClickHandler(e);
                        if (props.sortedWeights?.length === 1) {
                          handleCarouselChange(0);
                        }
                      }}
                      onKeyDown={(e) => {
                        onClickHandler(e);
                      }}
                      key={index}
                      role="button"
                      tabIndex={0}
                      aria-label={`${label} ${index + 1}`}
                    >
                      <CircleIcon sx={{ fontSize: '0.7em' }} />
                    </span>
                  );
                }}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IconButton onClick={onClickHandler} title={label} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', left: 0, zIndex: 1, backgroundColor: 'lightslategray' }}>
                      <ArrowBackIcon sx={{ color: 'white' }} />
                    </IconButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IconButton onClick={onClickHandler} title={label} style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '0.5em', zIndex: 1, backgroundColor: 'lightslategray' }}>
                      <ArrowForwardIcon sx={{ color: 'white' }} />
                    </IconButton>
                  )
                }
              >
                {props.sortedWeights?.map((weight) => (
                  <div key={weight.id} style={{ marginBottom: '2em' }}>
                    {props.weightItem ? (
                      <>
                        {isMobile || isTablet ? (
                          <>
                            <div>Aflaskráning</div>
                            <div>{'#' + weight.id}</div>
                          </>
                        ) : (
                          <div>Aflaskráning {'#' + weight.id}</div>
                        )}
                      </>
                    ) : (
                      <div>Aflaskráning</div>
                    )}
                  </div>
                ))}
              </Carousel>
            </div>

            <div style={{ display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: isMobile || isTablet ? '1em' : '0em', justifyContent: 'center' }}>
              <Avatar
                sx={{
                  bgcolor: theme.palette.mode === "dark" ? "#272727" : 'white',
                  fontSize: 16,
                  color: theme.palette.text.primary,
                  border: '1px solid #cdcdcd',
                  fontWeight: '500',
                  height: 40,
                  width: 150,
                  borderRadius: '1em'
                }}
                variant="rounded"
              >
                {currentTotalWeight + " kg" || "0 kg"}
              </Avatar>
              {props.selectedWeightNote.weightNoteType.id === WeightNoteTypes.PRODUCT_ID && (
                <Tooltip followCursor title={props.weightItem?.deductions?.length === 0 ? 'Vantar frádrátt til að uppreikna' : ''}>
                  <div>
                    <Button
                      sx={{ borderRadius: '1em', width: 180, marginLeft: '1em' }}
                      variant={props.selectedWeightNote.isClosed ? 'outlined' : 'contained'}
                      color={props.selectedWeightNote.isClosed ? 'primary' : 'secondary'}
                      disabled={(props.weightItem?.deductions?.length === 0 || loading)|| !props.weightItem}
                      startIcon={props.selectedWeightNote.isClosed ? <VisibilityIcon /> : <CalculateIcon />}
                      onClick={handleProductCalc}>
                      <span>{props.selectedWeightNote.isClosed ? 'Uppreikningur' : 'Uppreikna'}</span>
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={closeHandler}>
              <CancelIcon fontSize='large' />
            </IconButton>
          </div>
        </CatchRegistrationFormHeader>
        <DialogContent>
          {isMobile || isTablet ? ( // conditionally render steps if screen is small otherwise use space...
            <CatchRegistrationFormBody>
              <Stepper activeStep={activeStep} orientation="vertical" sx={{ height: '100%' }}>
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel onClick={() => handleSelectStep(index)} sx={{ cursor: 'pointer  !important' }}>
                      {step.label}
                    </StepLabel>
                    <StepContent
                      TransitionProps={{ unmountOnExit: false }}
                    >
                      <Typography variant='h6'>{step.inputFields}</Typography>
                      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        {index === steps?.length - 1 ? '' :
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            size='small'
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Næsta skref
                          </Button>}
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          size='small'
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Til baka
                        </Button>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </CatchRegistrationFormBody>) : (
            <MainRowContainer>
              <RowContainer>
                <ChildWrapper style={{ backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined }}>
                  <ChildData data-testid="catch">
                    <CatchRegistrationCatch
                      disabled={loading}
                      handleFormChange={handleFormChange}
                      allowedCombinations={props.catchCombinations}
                      weight={props.weightItem}
                      selectedWeightNote={props.selectedWeightNote}
                      fishAreas={props.fishAreas}
                      storageMethods={props.storageMethods}
                      destinies={props.destinies}
                      fishingStocks={props.fishingStocks}
                      conditions={props.conditions}
                      gears={props.gears}
                      containsExported={props.selectedWeightNote.weights?.some(weight => weight.destiny?.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID) ?? false}
                    />
                  </ChildData>
                </ChildWrapper>
              </RowContainer>

              <RowContainer>
                <ChildWrapper data-testid="weight" style={{ backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined }}>
                  <ChildData>
                    <CatchRegistrationWeight
                      disabled={loading}
                      handleFormChange={handleFormChange}
                      weightItem={props.weightItem}
                      selectedWeightNote={props.selectedWeightNote}
                      isExported={
                        formData.destinyId === CatchRegistrationDefaults.DESTINY_EXPORTED_ID ||
                        (props.weightItem?.destiny?.id === CatchRegistrationDefaults.DESTINY_EXPORTED_ID && !formData.destinyId)
                      }
                      stockIsScallops={
                        formData.fishStockId === CatchRegistrationDefaults.FISHING_STOCK_SCALLOPS_ID ||
                        (props.weightItem?.fishingStock?.id === CatchRegistrationDefaults.FISHING_STOCK_SCALLOPS_ID && !formData.fishStockId)
                      }
                    />
                  </ChildData>
                </ChildWrapper>
                <ChildWrapper data-testid="deduction" style={{ backgroundColor: theme.palette.mode === "dark" ? "#272727" : undefined }}>
                  <ChildData>
                    <CatchRegistrationDeduction
                      deductionModalOpen={deductionModalOpen}
                      toggleDeductionModalOpen={toggleDeductionModalOpen}
                      selectedWeightNote={props.selectedWeightNote}
                      weightItem={props.weightItem}
                      user={props.user}
                      setNewWeightItem={props.setNewWeightItem}
                      deductionTypes={props.deductionTypes}
                      showSnackbar={props.showSnackbar}
                      tempDeductions={tempDeductions}
                      setTempDeductions={handleTempDeductionChange}
                      vehicles={props.vehicles}
                      formData={formData}
                    />
                  </ChildData>
                </ChildWrapper>
              </RowContainer>
            </MainRowContainer>
          )}
          {!props.selectedWeightNote.isClosed && conditionalButtons()}
        </DialogContent>
      </CatchRegistrationFormWrapper>
    </Dialog >
  );
};

export default CatchRegistrationModal;