import React, { useState, useEffect, FC, Fragment } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import User from '../../../../../Models/UserModels/User';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/is';
import ExcelGenerator from '../../../../ExcelGenerator/ExcelGenerator';
import { getCatchValueReport } from '../../../../../Services/LondunarkerfiAPIService';
import CatchValue from '../../../../../Models/ReportModels/CatchValue';
import { logError } from '../../../../../Helpers/LogError';

interface CatchValueReportsProps {
  user: User;
  toggleOpen: () => void;
}

const CatchValueReport: FC<CatchValueReportsProps> = (props: CatchValueReportsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs().subtract(1, 'month'));
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [catchValue, setCatchValue] = useState<CatchValue[]>([]);

  useEffect(() => {
    fetchCatchValueReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  const fetchCatchValueReport = async () => {
    try {
      setLoading(true);
      if (props.user?.userPickedHarbour) {
        const formattedDateFrom = dateFrom?.format('DD.MM.YYYY');
        const formattedDateTo = dateTo?.format('DD.MM.YYYY');
        const response = await getCatchValueReport(
          props.user?.userPickedHarbour,
          formattedDateFrom,
          formattedDateTo
        );
        setCatchValue(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      logError(error);
    }
  };

  const columns: GridColDef[] = [
    { field: 'shipRegistrationNumber', headerName: 'Skip nr.', type: 'number', width: 120, resizable: false },
    { field: 'shipName', headerName: 'Skip heiti', width: 150, resizable: false },
    { field: 'landingDate', headerName: 'Löndun dags', valueGetter: (params) => dayjs(params.row.landingStarts).format('DD.MM.YYYY'), width: 160, resizable: false },
    { field: 'buyerName', headerName: 'Kaupandi', width: 175, resizable: false },
    { field: 'quantity', headerName: 'Magn (kg.)', type: 'number', width: 140, resizable: false },
    { field: 'value', headerName: 'Verðmæti (kr.)', type: 'number', width: 160, resizable: false },
  ];

  const handleDateFromChange = (date: Dayjs | null) => {
    setDateFrom(date);
  };

  const handleDateToChange = (date: Dayjs | null) => {
    setDateTo(date);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ExcelGenerator data={catchValue} disabled={loading} fileName='afla_verdmaeti' />
      </GridToolbarContainer>
    );
  }

  return (
    <Fragment>
      <div style={{ width: '100%', display: 'flex', gap: '1em', flexWrap: 'wrap' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='is'>
          <DatePicker
            sx={{ flex: '1', minWidth: '15em' }}
            disabled={loading}
            label='Dagsetning frá'
            value={dateFrom}
            onChange={(newValue: Dayjs | null) => handleDateFromChange(dayjs(newValue))}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
          />
          <DatePicker
            sx={{ flex: '1', minWidth: '15em' }}
            disabled={loading}
            label='Dagsetning til'
            value={dateTo}
            onChange={(newValue: Dayjs | null) => handleDateToChange(dayjs(newValue))}
            format="DD.MM.YYYY"
            maxDate={dayjs()}
          />
        </LocalizationProvider>
      </div>
      <div>
        <DataGrid
          rows={catchValue}
          columns={columns}
          loading={loading}
          autoHeight
          getRowId={(row) => row.quantity}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
    </Fragment >
  );
};

export default CatchValueReport;
