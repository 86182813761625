import styled from 'styled-components';

export const HomeWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const OverviewWrapper = styled.div`
    height: calc(100% - 56px);
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
`;

export const LeftWrapper = styled.div`
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

export const RightWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
`;

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 56px;
    `;

/*!
 * Bootstrap  v5.2.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
export const Card = styled.div`
    box-shadow: 0 0.375rem 0.75rem rgba(140,152,164,.075);
    --bs-card-border-width: 0.0625rem;
    --bs-card-border-color: rgba(231, 234, 243, 1);
    --bs-card-border-radius: 0.75rem;
    --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
    --bs-card-cap-bg: transparent;
    --bs-card-bg: #fff;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;

    width: 17em;

    @media only screen and (max-width: 1600px) {
        /* For smaller screens: */
        width: 14em;
    }

    @media only screen and (max-width: 1300px) {
        /* For smaller screens: */
        width: 12.5em;
    }
`;

export const IncompleteLandingsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const OverviewCardTitle = styled.h4`
    font-size: 1em;
    font-weight: bold;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 0.4em;
    padding-left: 1em;
    padding-top: 1em;
    margin: 0;
`;