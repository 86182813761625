import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

// https://docs.sentry.io/platforms/javascript/user-feedback/configuration/
const feedback = Sentry.feedbackIntegration({
  colorScheme: "system",
  isNameRequired: true,
  isEmailRequired: true,
  showBranding: false,
  triggerLabel: "",
  buttonLabel: "Tilkynna villu",
  formTitle: "Tilkynna villu",
  nameLabel: "Nafn",
  namePlaceholder: "Nafnið þitt",
  emailLabel: "Netfang",
  emailPlaceholder: "Netfangið þitt",
  isRequiredLabel: "*",
  addScreenshotButtonLabel: "Bæta við skjámynd",
  messageLabel: "Skýring",
  messagePlaceholder: "Útskýrðu hvað gerðist",
  submitButtonLabel: "Senda villu",
  cancelButtonLabel: "Hætta við",
  successMessageText: "Takk fyrir að láta vita!"
});

Sentry.init({
  dsn: "https://49e7415493a990763c9e5b10c1849476@o4507412390346752.ingest.de.sentry.io/4507418045907024",
  integrations: [
    Sentry.browserTracingIntegration(),
    feedback,
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.REACT_APP_ENVIRONMENT !== 'local',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  //<React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
