import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';


export const UnauthorizedWrapper = styled.div`
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;
`;

export const CardStyled = styled(Card)`
    margin: 1em;
    width: 35em;
    @media (max-width: 768px) {
        width: 20em;
    }
`;

export const CardMediaStyled = styled(CardMedia)`
    margin: 1em;
    border-radius: 1em;
    height: 35em;
    @media (max-width: 768px) {
        height: 20em;
    }
`;

export const TypographyStyled = styled(Typography)`
    text-align: center;
`;

export const CardActionStyled = styled(CardActions)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
