import { FC, useState } from "react";
import { Avatar, Card, Dialog, DialogTitle, Divider, IconButton, Stack, TextField, Theme, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { closeWeightNote } from "../../../../Services/EndurvigtunAPIService";
import ReWeightingDeviation from "../../../../Models/ReWeightingModels/ReWeightingDeviation";
import { GridColDef } from "@mui/x-data-grid";
import HelpIcon from '@mui/icons-material/Help';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SetMealIcon from '@mui/icons-material/SetMeal';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import PercentIcon from '@mui/icons-material/Percent';
import { LoadingButton } from "@mui/lab";
import ReWeightingWeightNoteEdit from "../../../../Models/ReWeightingModels/ReWeightingWeightNoteEdit";
import { useConfirm } from "material-ui-confirm";
import User from "../../../../Models/UserModels/User";
import { patchWeightNote } from "../../../../Services/LondunarkerfiAPIService";
import WeightNoteSubmit from "../../../../Models/WeightNoteModels/WeightNoteSubmit";
import { StyledDataGrid } from "./ConfirmCloseWeightNote.styled";
import WeightNote from "../../../../Models/WeightNoteModels/WeightNote";
import { MobilePaperComponent, PaperComponent } from "../../../../SharedComponents/Paper/CustomPaper";
import { logError } from "../../../../Helpers/LogError";
import { CardLabel } from "../ReWeightingList/ReWeightingList.styled";
import CatchRegistration from "../../../../Models/CatchRegistrationModels/CatchRegistration";

interface ConfirmCloseWeightNoteProps {
  user: User;
  open: boolean;
  toggleOpen: () => void;
  selectedWeightNote: WeightNote;
  deviation: ReWeightingDeviation | null;
  showSnackbar: (message: string, severity: string) => void;
  refetchLandingsReWeighter?: (pageNumber: number, refetching: boolean) => void;
  fetchCompleteLandings?: (pageNumber: number, refetching: boolean, requestedLandingId: number, requestedWeightNoteId: number) => void;
}

/**
 * Functional component for confirming closing a weightNote. If a deviation is spotted users need to insert comment explaining the deviation.
 * @param {ConfirmCloseWeightNoteProps} props 
 * @returns {JSX} renders the confirmation modal for closing weightNotes
 * 
 * Responsible for rendering the confirm modal for closing weightNotes. Shows users the deviation and asks for a comment explaining what happened.
 */

const ConfirmCloseWeightNote: FC<ConfirmCloseWeightNoteProps> = (props: ConfirmCloseWeightNoteProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deviationReason, setDeviationReason] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const desktopView = !isMobile && !isTablet;
  const confirm = useConfirm();

  const handleClose = () => {
    setDeviationReason('');
    props.toggleOpen();
  };

  const columns: GridColDef[] = [
    { field: "condition", headerName: "Ástand", valueGetter: (params) => params.row?.condition?.name, flex: 1 },
    { field: 'destiny', headerName: 'Afdrif', valueGetter: (params) => params.row?.destiny?.name, flex: 1 },
    { field: "fishingArea", headerName: "Veiðisvæði", valueGetter: (params) => params.row?.fishingArea?.name, flex: 1 },
    { field: "fishingStock", headerName: "Veiðistofn", valueGetter: (params) => params.row?.fishingStock?.name, flex: 1 },
    { field: "storageMethod", headerName: "Geymsluaðferð", valueGetter: (params) => params.row?.storageMethod?.name, flex: 1 },
    { field: "underKilogram", headerName: "Undirmál", type: "boolean", flex: 1 },
    { field: "quantity", headerName: "Magn", flex: 1 },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRowClassName = (params: any) => {
    return params.row?.hasDeviation ? 'row-with-deviation' : '';
  };

  const handleConfirmCloseWeightNote = () => {
    confirm({
      title: 'Ertu alveg viss?',
      description: `Þetta mun ljúka endurvigtun. Þú munt ekki geta breytt skráningu eftir að henni er lokið.`,
      confirmationText: 'Ljúka',
      cancellationText: 'Hætta við',
      confirmationButtonProps: { variant: 'contained', color: 'success' },
      cancellationButtonProps: { variant: 'outlined' },
    })
      .then(() => handleCloseWeightNote())
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => { });
  }

  const handleCloseWeightNote = async () => {
    setLoading(true);
    try {
      if (props.user?.isReweighingUser && props.refetchLandingsReWeighter) {
        const body: ReWeightingWeightNoteEdit = {
          deviationReason: deviationReason,
        };
        await closeWeightNote(props.selectedWeightNote?.id, body);
        props.refetchLandingsReWeighter(1, true);
      } else if (props.fetchCompleteLandings) {
        const body: WeightNoteSubmit = {
          isClosed: true,
          deviationReason: deviationReason,
        };
        await patchWeightNote(props.selectedWeightNote.id, body);
        props.fetchCompleteLandings(1, true, props.selectedWeightNote.landingId, props.selectedWeightNote.id);
      }
      props.showSnackbar('Endurvigtun lokið!', 'success');
      setLoading(false);
      handleClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setLoading(false);
      if (error.response && error.response.data) {
        const errorMsg = error.response.data;
        props.showSnackbar(errorMsg, 'error');
      }
      else {
        logError(error);
        props.showSnackbar('Óvænt villa kom upp!', 'error');
      }
    }
  }

  const handleDeviationReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeviationReason(e.target.value);
  }

  const weightsAsCards = (weight: CatchRegistration, theme: Theme) => {
    return (
      <Card
        key={weight.id}
        style={{
          marginBottom: '1em',
          cursor: 'pointer',
          height: 'auto',
          backgroundColor: weight.hasDeviation ? "#ffcccb" : theme.palette.mode === 'dark' ? "#272727" : undefined,
        }}
      >
        <Stack direction={'row'} justifyContent="space-between" alignItems="center" width={'100%'}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1em', flexWrap: 'wrap' }}>
            <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <SetMealIcon color="primary" />
                {weight.fishingStock?.name}
              </Stack>
            </Typography>
            <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
              <Stack direction="row" alignItems="center" gap={1}>
                <SignLanguageIcon color="primary" />
                {weight.condition?.name}
              </Stack>
            </Typography>
          </div>
          <Avatar sx={{ width: '4em', height: '4em' }}>
            {weight.quantity + " kg."}
          </Avatar>
        </Stack>

        <Divider sx={{ marginTop: '1em', marginBottom: '1em' }} />

        <Stack direction={'row'} width={'100%'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardLabel>
            <LocationOnIcon color="primary" />
            {weight.fishingArea?.name}
          </CardLabel>

          <CardLabel>
            <AcUnitIcon color="primary" />
            {weight.storageMethod?.name}
          </CardLabel>

          <CardLabel>
            <ImportExportIcon color="primary" />
            {weight.destiny?.name}
          </CardLabel>
        </Stack>

        {weight.icePercentage !== 0 && (
          <Stack direction={'row'} width={'100%'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack direction={'row'}>
              <PercentIcon color="primary" />
              {weight.icePercentage + '%'}
            </Stack>
            {weight.underKilogram && 'Undirmál'}
          </Stack>
        )}
      </Card>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperComponent={desktopView ? PaperComponent : MobilePaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth={"lg"}
    >
      <DialogTitle
        id="draggable-dialog-title"
        sx={{ fontSize: '1.5em', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', gap: '0.4em', lineHeight: '1.5em', cursor: 'move' }}
      >
        Ljúka endurvigtun
        <Tooltip
          title="Þú ert að fara ljúka endurvigtun. Þú munt ekki geta breytt þessum tölum eftir að hafa lokið endurvigtun. 
            Ef frávik finnst í endurvigtun ertu beðin að útskýra frávikið frekar svo Fiskistofa geti samþykkt endurvigtunina. 
            Þú ættir að sjá línur sem skila fráviki."
          placement="bottom"
          sx={{ cursor: 'pointer' }}>
          <HelpIcon color="primary" />
        </Tooltip>
        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClose}>
          <CancelIcon fontSize='large' />
        </IconButton>
      </DialogTitle>
      <TextField
        label="Ástæða fráviks"
        multiline
        value={deviationReason}
        onChange={handleDeviationReasonChange}
        required
        rows={2}
        variant="outlined"
        fullWidth
        placeholder="Hér skal skrifa ástæðu fráviks og útskýra hvað gerðist. Umsjónarmaður Fiskistofu sér þessa athugasemd og þarf að samþykkja."
        inputProps={
          {
            maxLength: 4000
          }
        }
      />
      <LoadingButton
        fullWidth
        onClick={handleConfirmCloseWeightNote}
        loading={loading}
        disabled={loading || deviationReason.length === 0}
        endIcon={<CheckIcon />}
        color='success'
        variant="contained"
      >
        <span>Ljúka</span>
      </LoadingButton>

      <>
        <Typography variant="h6" align="center">
          <strong>Upprunaleg vigtun</strong>
        </Typography>
        {desktopView ? (
          <StyledDataGrid
            rows={props.deviation?.fromHarbourWeightNote?.weights || []}
            columns={columns}
            loading={loading}
            getRowClassName={getRowClassName}
          />
        ) : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1em' }}>
          {props.deviation?.fromHarbourWeightNote?.weights ? props.deviation.fromHarbourWeightNote.weights.map((weight) => weightsAsCards(weight, theme)) : ''}
        </div>}

      </>
      <>
        <Typography variant="h6" align="center">
          <strong>Endurvigtun</strong>
        </Typography>
        {desktopView ? (
          <StyledDataGrid
            rows={props.deviation?.fromReWeighterWeightNote?.weights || []}
            columns={columns}
            loading={loading}
            getRowClassName={getRowClassName}
          />
        ) : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: '1em' }}>
          {props.deviation?.fromReWeighterWeightNote?.weights ? props.deviation.fromReWeighterWeightNote.weights.map((weight) => weightsAsCards(weight, theme)) : ''}
        </div>}
      </>
    </Dialog>
  );
};

export default ConfirmCloseWeightNote;