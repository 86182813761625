const WeightNoteTypes = {
  TO_REWEIGHING: 4,
  TO_HOME_REWEIGHING: 17,
  TO_REWEIGHTING_OVERSEAS: 14,
  FINAL_REWEIGHTING: 2,
  FROM_REWEIGHTER: 22,
  FROM_REWEIGHTER_OVERSEAS: 23,
  PRODUCT_SAMPLE_ID: 3,
  PRODUCT_ID: 11,
  // default weight not type.
  HARBOUR_SCALE_ID: 6
};

export default WeightNoteTypes;